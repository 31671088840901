import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import AddIcon from '@mui/icons-material/Add';
import FormField from '../../../Components/common-components/form-input';
import InputIcon from '@mui/icons-material/Input';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Switch } from '@mui/material';
import TableHeadings from "../../../Components/common-components/table-headings";
import CreateIcon from '@mui/icons-material/Create';
import Pagination from "../../../Components/Pagination"
import DeleteIcon from '@mui/icons-material/Delete';
import UseProcedureList from './Hooks/useProcedureList';
import Modal from "../../../Components/PopUpModal";
import MultipleSelect from "../../../Components/MultipleSelect";
import { Spinner } from '../../../Components/common-components/spinner'
import { getTableHead, Permission } from '../../../Utils';
import { Permission_Message } from '../../../Components/common-components/permissionMessage';
import { MdAdsClick } from "react-icons/md";
import '../../../Components/common-components/form-input.css'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
const TableHeadData = [
  { label: '#' },
  { label: 'Category' },
  { label: 'Code' },
  { label: 'Alt Code' },
  { label: 'Description' },
  { label: 'Abbreviation ' },
  { label: 'Fees 1' },
  { label: 'Fees 2' },
  { label: 'Bill To Insurance' },
  { label: "Procedure Time" },
  { label: 'Teeth' },
  { label: 'Tooth Surface' },
  { label: 'Sales Tax' },
  { label: 'Status' },
  { label: 'Action' },
]

const ProcedureList = () => {
  const { data, methods } = UseProcedureList()
  const {
    deleteModal,
    procedureModal,
    surfaceModal,
    toothsModal,
    filter,
    spinner,
    procedureList,
    surfaces,
    controller,
    totalCount,
    procedureInputs,
    selectedSurfaceCounts,
    selectedToothsCounts,
    teethsList,
    procedureError,
    billableArry,
    categoryList,
    timeArry,

    // edit form table valuye
    editRowIndex,
    editableData,
  } = data
  const {
    setDeleteModal,
    setProcedureModal,
    setSurfaceModal,
    setToothsModal,
    setFilter,
    setController,
    clearForm,
    ApplyFilter,
    resetFilter,
    status,
    handleInputChange,
    handleSelectedSurface,
    handleSelectedTooths,
    handleSubmitSurfaces,
    handleSubmitTooths,
    handleBlur,
    getDetails,
    handleAddSubmit,
    handleupdateSubmit,
    handleDelete,
    // Table Form Edit---------------
    handlePocidureCodeInputChange,
    handleSave,
    handleCancel,
    setEditRowIndex,
    setEditableData,
    handleSetFormDetails,
  } = methods

  const View_Permission = Permission({ permission: 'Setup-Procedure Code-Able to View/List Procedure Code' })
  const Add_Permission = Permission({ permission: 'Setup-Procedure Code-Able to add Procedure Code' })
  const Edit_Permission = Permission({ permission: 'Setup-Procedure Code-Able to edit Procedure Code' })
  const Delete_Permission = Permission({ permission: 'Setup-Procedure Code-Able to Delete Procedure Code' })
  const Archive_UnArchive_Permission = Permission({ permission: 'Setup-Procedure Code-Able to Active/In-Active Procedure Code' })
  const tableHeadData = getTableHead(Edit_Permission, Delete_Permission, TableHeadData)

  return (
    <>
      {spinner && <Spinner />}
      <div className='container-fluid'>
        <div className="d-sm-flex d-md-flex d-lg-flex align-items-center justify-content-between">
          <div className='d-flex'>
            <div className='mt-2'>
              <FormField data={{ placeholder: 'Search', rightIcon: '', type: 'text' }} value={filter?.search} onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))} />
            </div>
            <div className="d-flex ms-2">
              <button className="theme-btn bgDarkBlue  mt-3 mx-lg-2 mx-md-2 mx-sm-2 me-2" onClick={ApplyFilter}> <SearchIcon style={{ height: "20px" }} /> Search</button>
              <button className="theme-btn bgDarkBlue  mt-3 mx-lg-2 mx-md-2 mx-sm-2 me-2" onClick={resetFilter}> <RestartAltIcon style={{ height: "20px" }} /> Reset</button>
              {Add_Permission && <button className="theme-btn bgLightGreen mt-3 ms-2" onClick={() => setProcedureModal({ open: true })}> <AddIcon style={{ height: "20px" }} /> Add New</button>}
            </div>
          </div>
          <div className="d-flex">
            <button className="theme-btn bgDarkBlue2 mt-3 me-2"> <SaveAltIcon style={{ height: "20px" }} />Import</button>
            <button className="theme-btn bgDarkBlue2 mt-3 ms-2"> <InputIcon style={{ height: "20px" }} />Export</button>
          </div>
        </div>
        {
          View_Permission ?
            <div>
              <div className="col-12 mt-3 pb-3" >
                <table className="customTable PROCEDUE_INPUT_STYLE">
                  <TableHeadings TableHeadData={tableHeadData} />
                  <tbody>
                    {
                      procedureList?.map((resp, index) =>
                        <tr key={index}>
                          <td >{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>

                          {/* <td >{resp?.category}
                            <CreateIcon className='cursor ms-2' color="primary" titleAccess='Edit' />
                            <DoneIcon />
                            <CloseIcon />                        </td>
                          <td >{resp?.code}
                            <CreateIcon className='cursor ms-2' color="primary" titleAccess='Edit' />
                            <DoneIcon />
                            <CloseIcon />
                          </td> */}

                          {/* Category */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `category${index}` ? (
                                // <MultipleSelect
                                //   // isRequired={true}
                                //   // label={"Category"}
                                //   multiple={false}
                                //   searchBy={"name"}
                                //   keyName={"name"}
                                //   name={"category"}
                                //   onChange={(e) => handlePocidureCodeInputChange({ target: { name: "category", value: e } }, 'category')}
                                //   value={"id"}
                                //   selectedvalues={editableData?.category}
                                //   // error={procedureError?.category}
                                //   options={categoryList}
                                // />
                                <select
                                  name="category"
                                  value={editableData?.category ?? ""}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'category')}
                                >
                                  <option value="" disabled>Select</option>
                                  {categoryList?.map((category) => {
                                    console.log(category,"at")
                                    return <option key={category?.id} value={category?.id}>{`${category?.name}(${category?.id})`}</option>
                                  }
                                  )}
                                </select>

                              ) : (`${resp?.category} `)}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `category${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`category${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>

                          {/* Code */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `code${index}` ? (
                                <input
                                  value={editableData?.code}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'code')}
                                />
                              ) : resp?.code}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `code${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`code${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>

                          {/* Alt Code */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `altCode${index}` ? (
                                <input
                                  value={editableData?.altCode}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'altCode')}
                                />
                              ) : resp?.altCode}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `altCode${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`altCode${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          {/* description */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `description${index}` ? (
                                <textarea
                                className="form-control font-12"
                                  role='3'
                                  value={editableData?.description}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'description')}
                                />
                              ) : resp?.description}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `description${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`description${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          {/*abbrev  */}

                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `abbrev${index}` ? (
                                <input
                                  value={editableData?.abbrev}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'abbrev')}
                                />
                              ) : resp?.abbrev}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `abbrev${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`abbrev${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          {/*fees1  */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `fees1${index}` ? (
                                <input
                                  type="number"
                                  value={editableData?.fees1}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'fees1')}
                                />
                              ) : resp?.fees1}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `fees1${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`fees1${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          {/* fees2 */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `fees2${index}` ? (
                                <input
                                  type="number"
                                  value={editableData?.fees2}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'fees2')}
                                />
                              ) : resp?.fees2}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `fees2${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`fees2${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          {/*billable  */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `billable${index}` ? (
                                // <input
                                //   value={editableData?.billable}
                                //   onChange={(e) => handlePocidureCodeInputChange(e, 'billable')}
                                // />

                                <select
                                  name="billable"
                                  value={editableData?.billable === true ? "true" : "false"}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'billable')}
                                >
                                  <option value="" disabled>Select</option>
                                  {billableArry?.map((val) => {
                                    return <option key={val?.value} value={val?.value}>{val?.label}</option>
                                  }
                                  )}
                                </select>

                              ) : resp?.billable ? "Yes" : "No"}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `billable${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`billable${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>


                          {/* time */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `time${index}` ? (
                                <input
                                  value={editableData?.time}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'time')}
                                />
                              ) : resp?.time}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `time${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`time${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>

                            </div>
                          </td>

                          {/* ToothData */}


                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `ToothData${index}` ? (

                                <>
                                  <input className='form-group box' type="number" value={selectedToothsCounts} readOnly />
                                  <MdAdsClick className='ms-2 cursor font-18' onClick={() => { setToothsModal((prev) => ({ ...prev, data: '', open: true })); handleSelectedTooths() }} />
                                </>
                              ) : resp?.ToothData?.length}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `ToothData${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`ToothData${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          {/* surfaceData */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `surfaceData${index}` ? (
                                // <input
                                //   value={editableData?.surfaceData}
                                //   onChange={(e) => handlePocidureCodeInputChange(e, 'surfaceData')}
                                // />
                                <>
                                  <input className='form-group box' type="number" value={selectedSurfaceCounts} readOnly />
                                  <MdAdsClick className='ms-2 cursor font-18' onClick={() => setSurfaceModal((prev) => ({ ...prev, data: '', open: true }))} />
                                </>

                              ) : resp?.surfaceData?.length}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `surfaceData${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`surfaceData${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          {/* isSaleTax */}
                          {/* <td >{resp?.category}
                            <CreateIcon className='cursor ms-2' color="primary" titleAccess='Edit' />
                            <DoneIcon />
                            <CloseIcon />
                          </td red> */}
                          <td>
                            <div className='d-flex'>
                              {editRowIndex === `isSaleTax${index}` ? (
                                <select
                                  name="isSaleTax"
                                  // value={editableData?.isSaleTax ?? ""}
                                  value={editableData?.isSaleTax === true ? "true" : "false"}
                                  onChange={(e) => handlePocidureCodeInputChange(e, 'isSaleTax')}
                                >
                                  <option value="" disabled>Select</option>
                                  {billableArry?.map((val) => {
                                    return <option key={val?.value} value={val?.value}>{val?.label}</option>
                                  }
                                  )}
                                </select>
                              ) : resp?.isSaleTax ? "Yes" : "No"}
                              {/* Icons, only visible on hover */}
                              <div className='icon-container'>
                                {editRowIndex === `isSaleTax${index}` ? (
                                  <>
                                    <DoneIcon className='cursor ms-2 font-16' titleAccess='Save' onClick={() => handleupdateSubmit(resp, index)} />
                                    <CloseIcon className='cursor red ms-2 font-16' titleAccess='Cancel' onClick={handleCancel} />
                                  </>
                                ) : (
                                  <>
                                    <CreateIcon className='cursor ms-2 font-16' color="primary" titleAccess='Edit' onClick={() => {
                                      setEditRowIndex(`isSaleTax${index}`);
                                      handleSetFormDetails(resp);
                                    }} />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            <Switch onChange={(e) => status(resp, e?.target?.checked)} title='Status Active/In-active' checked={resp?.isActive} disabled={Archive_UnArchive_Permission ? false : true} />
                          </td>
                          {
                            !Delete_Permission && !Edit_Permission ? null :
                              <td>
                                <div className='d-flex'>
                                  {
                                    Delete_Permission &&
                                    <DeleteIcon className='cursor red font-21' titleAccess='Delete' onClick={() => setDeleteModal((prev) => ({ ...prev, data: resp, open: true }))} />
                                  }
                                  {/* {
                                    Edit_Permission &&
                                    <CreateIcon className='cursor ms-2' color="primary" titleAccess='Edit' onClick={() => { setProcedureModal((pre) => ({ ...pre, open: true, data: resp })); getDetails(resp?.id) }} />
                                  } */}
                                </div>
                              </td>
                          }
                        </tr>
                      )
                    }
                  </tbody>
                </table>
                {!procedureList?.length && <div className="text-center mt-2">No record found</div>}
                <div className="d-flex justify-content-center mt-2">
                  {
                    totalCount > 10 && <Pagination count={totalCount} controller={controller} setController={setController} />
                  }
                </div>
              </div>
            </div>
            : Permission_Message
        }

        {/* Form Modal add Procedure */}
        <Modal className="p-0"
          size="lg" show={procedureModal?.open} onHide={() => {
            clearForm();
            setProcedureModal({ data: {}, open: false })
          }}
          headerContent={<h5 className="py-2 mb-0 fw-6">{`${procedureModal?.data?.id ? 'Edit' : 'Add'}`} Procedure Code</h5>}
          bodyContent={
            <div >
              <div className="row p-4 pb-5">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <MultipleSelect
                    isRequired={true}
                    label={"Category"}
                    multiple={false}
                    searchBy={"name"}
                    keyName={"name"}
                    name={"category"}
                    onChange={(e) => handleInputChange({ target: { name: "category", value: e } })}
                    value={"id"}
                    selectedvalues={procedureInputs?.category}
                    error={procedureError?.category}
                    options={categoryList}
                  />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <FormField
                    required={true}
                    name={'code'}
                    value={procedureInputs?.code}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleInputChange(e)}
                    error={procedureError?.code}
                    data={{ label: 'Code', placeholder: '', type: 'text' }}
                  />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <FormField
                    // required={true}
                    name={'alt_code'}
                    value={procedureInputs?.alt_code}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleInputChange(e)}
                    error={procedureError?.alt_code}
                    data={{ label: 'Alt Code', placeholder: '', type: 'text' }}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <FormField
                    required={true}
                    name={'description'}
                    value={procedureInputs?.description}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleInputChange(e)}
                    error={procedureError?.description}
                    data={{ label: 'Description', placeholder: '', type: 'text' }}
                  />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <FormField
                    required={true}
                    name={'abbrev'}
                    value={procedureInputs?.abbrev}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleInputChange(e)}
                    error={procedureError?.abbrev}
                    maxLength={10}
                    data={{ label: 'Abbreviation', placeholder: '', type: 'text' }} />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <FormField
                    // required={true}
                    name={'fees1'}
                    value={procedureInputs?.fees1}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleInputChange(e)}
                    error={procedureError?.fees1}
                    maxLength={10}
                    data={{ label: 'Fee 1', placeholder: '', type: 'number' }} />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <FormField
                    // required={true}
                    name={'fees2'}
                    value={procedureInputs?.fees2}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleInputChange(e)}
                    error={procedureError?.fees2}
                    maxLength={10}
                    data={{ label: 'Fee 2', placeholder: '', type: 'number' }} />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <MultipleSelect
                    isRequired={true}
                    label={"Bill To Insurance"}
                    multiple={false}
                    searchBy={"name"}
                    keyName={"label"}
                    name={"billable"}
                    onChange={(e) => { handleInputChange({ target: { name: "billable", value: e } }) }}
                    value={"value"}
                    selectedvalues={procedureInputs?.billable}
                    error={procedureError?.billable}
                    options={billableArry}
                  />
                </div>

                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <MultipleSelect
                    isRequired={true}
                    label={"Procedure Time"}
                    multiple={false}
                    searchBy={"value"}
                    keyName={"title"}
                    name={"time"}
                    onChange={(e) => handleInputChange({ target: { name: "time", value: e } })}
                    value={"value"}
                    selectedvalues={procedureInputs?.time}
                    error={procedureError?.time}
                    options={timeArry}
                  />
                </div> */}
                <div className="col-lg-4 col-sm-6 col-12 pt-sm-4 pt-3 fw-6">
                  <span className='font-11'>Sales Tax</span> <Switch className='ms-4' checked={procedureInputs?.isSaleTax}
                    onChange={(e) => handleInputChange({ target: { name: "isSaleTax", value: e?.target?.checked } })}
                    selectedvalues={procedureInputs?.isSaleTax} />
                </div>

                <div className="col-lg-4 col-sm-6 col-12">
                  <label className='fw-6 font-11 p-0 '> Teeth </label>
                  <div className='d-flex align-items-center'>
                    <input className='form-group box' type="number" value={selectedToothsCounts} readOnly />
                    <MdAdsClick className='ms-2 cursor' onClick={() => { setToothsModal((prev) => ({ ...prev, data: '', open: true })); handleSelectedTooths() }} />
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-12">
                  <label className='fw-6 font-11 p-0 '> Tooth Surface  </label>
                  <div className='d-flex align-items-center'>
                    <input className='form-group box' type="number" value={selectedSurfaceCounts} readOnly />
                    <MdAdsClick className='ms-2 cursor' onClick={() => setSurfaceModal((prev) => ({ ...prev, data: '', open: true }))} />
                  </div>
                </div>
                {/* <div className="col-lg-4 col-sm-6 col-12 pt-sm-5 pt-3 fw-6">
                  Sales Tax <Switch className='ms-4' checked={procedureInputs?.isSaleTax}
                    onChange={(e) => handleInputChange({ target: { name: "isSaleTax", value: e?.target?.checked } })}
                    selectedvalues={procedureInputs?.isSaleTax} />
                </div> */}
              </div>

              <div className="d-flex justify-content-end border-top p-3">
                <div>
                  <button type="submit" className="theme-btn bgLightGray col-11 px-5" onClick={() => { clearForm(); setProcedureModal({ data: {}, open: false }) }}>Discard</button>
                </div>
                <div>
                  {procedureModal?.data?.id ? <button type="button" className="theme-btn bgDarkBlue col-11 px-5" onClick={handleupdateSubmit}> Update</button>
                    :
                    <button type="button" className="theme-btn bgDarkBlue col-11 ms-2 px-5" onClick={handleAddSubmit}>Save</button>
                  }
                </div>
              </div>
            </div>
          }
        />

        {/* Delete Modal of Procedure Code */}
        <Modal
          size="md" headerBorderBottom={true} show={deleteModal?.open} onHide={() => { setDeleteModal({ data: {}, open: false }) }}
          bodyContent={
            <div >
              <div className="text-center col-12">
                <div className='mt-3 mb-4'>
                  <DeleteIcon className='cursor red' sx={{ fontSize: '50px' }} />
                </div>
                <div className="fw-6 font-22">Delete Procedure Code</div >
                <div className="grey-color font-15 my-2">
                  {/* <span> {deleteModal?.data?.description} </span> */}
                  You are about to delete this procedure <br /> Are you sure?
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 mb-4">
                <button type="button" className="theme-btn  col-5 font-12 fw-5 bgRed" onClick={handleDelete}> Delete</button>
              </div>
            </div>
          }
        />



        {/* surface Modal of tooths */}
        <Modal
          size="sm" headerBorderBottom={true} show={surfaceModal?.open} onHide={() => { setSurfaceModal({ data: {}, open: false }) }}
          bodyContent={
            <div >
              <div className='fw-6 font-14'> Select Tooth Surface  </div>
              <div>
                <FormGroup>
                  {surfaces.map((item, index) => (
                    <FormControlLabel key={index} control={
                      <Checkbox checked={item.checked} onChange={(event) => handleSelectedSurface(event, item.label)} />}
                      label={item.label}
                    />
                  ))}
                </FormGroup>

              </div>
              <div className="d-flex justify-content-center border-top pt-3">

                <button type="submit" className="theme-btn bgLightGray " onClick={() => { setSurfaceModal({ data: {}, open: false }) }}>Cancel</button>
                {procedureModal?.data?.id ? <button type="button" className="theme-btn bgDarkBlue ms-2" onClick={handleSubmitSurfaces}> Update</button>
                  : <button type="button" className="theme-btn bgDarkBlue ms-2" onClick={handleSubmitSurfaces}>OK</button>
                }
              </div>
            </div>
          }
        />

        {/* all tooths Modal  */}
        <Modal
          size="xl" headerBorderBottom={true} show={toothsModal?.open} onHide={() => { setToothsModal({ data: {}, open: false }) }}
          bodyContent={
            <div >
              <div className='fw-6 font-14'> All Permanent and  Primary Tooths  </div>
              <div className='text-center'>
                <FormGroup className="d-block">
                  {teethsList.slice(0, 16).map((item, index) => (
                    <FormControlLabel
                      key={index}
                      className={`border p-2 ${item.selected && 'bgBlue2 white'}`}
                      control={
                        <Checkbox
                          className="d-none"
                          checked={item.selected}
                          onChange={(event) => handleSelectedTooths(event, item.toothNumber)}
                        />
                      }
                      label={item.toothNumber}
                    />
                  ))}
                </FormGroup>
                <div className="my-3">
                  <FormGroup className="d-block">
                    {teethsList?.slice(16, 36)?.map((item, index) => (
                      <React.Fragment key={index}>
                        {index === 10 && <br />}
                        <FormControlLabel
                          className={`border p-2 m-2 ${item.selected && 'bgBlue2 white'}`}
                          control={
                            <Checkbox
                              className="d-none"
                              checked={item.selected}
                              onChange={(event) => handleSelectedTooths(event, item.toothNumber)}
                            />
                          }
                          label={item.toothNumber}
                        />
                      </React.Fragment>
                    ))}
                  </FormGroup>
                </div>


                <div className="my-3">
                  <FormGroup className="d-block">
                    {teethsList.slice(36).map((item, index) => (
                      <FormControlLabel
                        key={index}
                        className={`border p-2 ${item.selected && 'bgBlue2 white'}`}
                        control={
                          <Checkbox
                            className="d-none"
                            checked={item.selected}
                            onChange={(event) => handleSelectedTooths(event, item.toothNumber)}
                          />
                        }
                        label={item.toothNumber}
                      />
                    ))}
                  </FormGroup>
                </div>
              </div>
              <div className="d-flex justify-content-center border-top pt-3">
                <button type="submit" className="theme-btn bgLightGray " onClick={() => { setToothsModal({ data: {}, open: false }); clearForm() }}>Cancel</button>
                {procedureModal?.data?.id ? <button type="button" className="theme-btn bgDarkBlue ms-2" onClick={handleSubmitTooths}> Update</button>
                  :
                  <button type="button" className="theme-btn bgDarkBlue ms-2" onClick={handleSubmitTooths}>OK</button>
                }
              </div>
            </div>
          }
        />

      </div>
    </>
  )
}

export default ProcedureList