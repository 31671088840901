import { createSlice } from "@reduxjs/toolkit";

const familyMemberSlice = createSlice({
    name: "familyMemberData",
    initialState: [],
    reducers: {
        familyMemberDetails(state, action) {
            return state = action.payload;
        }
    }
})
export const { familyMemberDetails } = familyMemberSlice.actions;
export default familyMemberSlice.reducer;