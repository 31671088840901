import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const Spinner = () => {
    return (
        <div style={{zIndex:30000000001}}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1100011111111111 }} open>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
