import React from 'react'
import FormField from "../../Components/common-components/form-input"
import { GenderOptions, MaritalStatusOptions, bloodGroupOptions, patientStatusOptions, preferredPronounOption, relationShipArr, Check, RegionOptions, PaymentOptions, TitleArr, } from '../../constant'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Avatar } from '@mui/material'
import MultipleSelect from "../../Components/MultipleSelect";
import DateInput from "../../Components/common-components/DateInput"
import PhoneNumber from "../../Components/common-components/PhoneInput";
import { userLicenceURL } from '../../repository/common';
import SelectInput from "../../Components/common-components/selectInput"

const AddPatient = ({ data, methods, isEdit }) => {
    const { addPatientModal,
        patientFormInput,
        countryListData,
        stateListData,
        cityListData,
        patientError,
        providerListData,
        HygienistListData,
        licenseImage,
        FileRef,
        billingTypeList,
        allImage
    } = data
    const {
        setSpinner,
        setAddPatientModal,
        getState,
        getCity,
        handleInputChange,
        handleAddSubmit,
        handleUpdateSubmit,
        UploadImage,
        setLicenseImage,
        removeFile,
        discardFunction,
        setAllImage,
    } = methods

    const { title, firstName, middleName, lastName, salutation, preferredPronoun, gender, maritalStatus, dob, age, status, bloodGroup, isOrthoPatient, street, suiteAptNumber, region, country, state, city, zipcode, preferredContactMethod, phone, workPhone, email, emergencyContactPersonName, emergencyContactPersonPhoneNumber,
        relationshipWithPatient, paymentMode, billingType, referenceTo, referenceBy, responsibleParty, language, policyHolder, employmentType, preferredProvider, preferredHygienist, driversLicense } = patientFormInput

    return (
        <div className=' m-3 m-lg-4 mt-4 mt-lg-2'>
            <div className="row" style={{ height: "75vh", overflowY: "auto" }}>
                {/* Basic Information */}
                <div className="col-lg-7 col-12 mt-3">
                    <div className='d-flex justify-content-center shadow-sm bgLightBlue1 font-14 fw-5 text-white p-1'>Basic Information</div>
                    <div className='row'>
                        <div className="col-lg-2 col-sm-6 col-12 ">
                            {/* <FormField
                                maxLength={50}
                                required={false}
                                name={'title'}
                                value={title}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.title}
                                data={{ label: 'Title', placeholder: '', type: 'text' }} /> */}

                            <SelectInput ClassName={'mt-3'}
                                LabelButton
                                Lable={'Title'}
                                // multiple={true}
                                Options={TitleArr}
                                keyName={'label'}
                                // extrakey={"leaveType"}
                                Name="title"
                                SelectValue={title}
                                HandleChange={(e) => handleInputChange(e)}
                                Error={patientError.title}
                                variant={'outlined'}
                            // isRequired={true}
                            // disabled={true}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={true}
                                label={"Preferred Pronoun"}
                                multiple={false}
                                options={preferredPronounOption}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"preferredPronoun"}
                                onChange={(e) => handleInputChange({ target: { name: "preferredPronoun", value: e } })}
                                value={"value"}
                                selectedvalues={preferredPronoun}
                                error={patientError?.preferredPronoun}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                required={false}
                                name={'salutation'}
                                value={salutation}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.salutation}
                                data={{ label: 'Salutation (Dear)', placeholder: '', type: 'text' }} />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={false}
                                label={"Blood Group"}
                                multiple={false}
                                options={bloodGroupOptions}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"bloodGroup"}
                                onChange={(e) => handleInputChange({ target: { name: "bloodGroup", value: e } })}
                                value={"value"}
                                selectedvalues={bloodGroup}
                                error={patientError?.bloodGroup}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                required={true}
                                name={'firstName'}
                                value={firstName}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.firstName}
                                data={{ label: 'First Name', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'middleName'}
                                value={middleName}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.middleName}
                                data={{ label: 'Middle Name', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                required={true}
                                name={'lastName'}
                                value={lastName}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.lastName}
                                data={{ label: 'Last Name', placeholder: '', type: 'text' }} />
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={false}
                                label={"Gender"}
                                multiple={false}
                                options={GenderOptions}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"gender"}
                                onChange={(e) => handleInputChange({ target: { name: "gender", value: e } })}
                                value={"value"}
                                selectedvalues={gender}
                                error={patientError?.gender}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <DateInput
                                maxDate={new Date()}
                                error={patientError?.dob}
                                isRequired={true}
                                size="small" // You can customize the size here
                                variant="outlined" // You can customize the variant here
                                className=" form-field-design w-100 fullWidth" // You can customize the width here
                                // isRequired={true}
                                Label={'D.O.B'}
                                SelectedDate={dob || null}
                                handleDateChange={(date) => handleInputChange({ target: { name: "dob", value: date } })}
                            />
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <FormField
                                maxLength={3}
                                disabled={true}
                                name={'age'}
                                value={age}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.age}
                                data={{ label: 'Age', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                            <MultipleSelect
                                isRequired={true}
                                label={"Status"}
                                multiple={false}
                                options={patientStatusOptions}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"status"}
                                onChange={(e) => handleInputChange({ target: { name: "status", value: e } })}
                                value={"value"}
                                selectedvalues={status}
                                error={patientError?.status}
                            />
                        </div>



                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <MultipleSelect
                                isRequired={false}
                                label={"Marital Status"}
                                multiple={false}
                                options={MaritalStatusOptions}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"maritalStatus"}
                                onChange={(e) => handleInputChange({ target: { name: "maritalStatus", value: e } })}
                                value={"value"}
                                selectedvalues={maritalStatus}
                                error={patientError?.maritalStatus}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                            <MultipleSelect
                                label={"Is Ortho Patient"}
                                multiple={false}
                                options={Check}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"isOrthoPatient"}
                                onChange={(e) => handleInputChange({ target: { name: "isOrthoPatient", value: e } })}
                                value={"value"}
                                selectedvalues={isOrthoPatient}
                                error={patientError?.isOrthoPatient}
                            />
                        </div>
                    </div>
                </div>
                {/* Basic Information */}

                {/* Address Information */}
                <div className="col-lg-5 col-12 mt-3">
                    <div className='d-flex justify-content-center shadow-sm bgLightBlue1 font-14 fw-5 text-white p-1'>Address Information</div>
                    <div className='row'>
                        <div className="col-12 ">
                            <FormField
                                maxLength={50}
                                name={'street'}
                                value={street}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.street}
                                data={{ label: 'Street', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-12 ">
                            <FormField
                                maxLength={50}
                                name={'suiteAptNumber'}
                                value={suiteAptNumber}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.suiteAptNumber}
                                data={{ label: 'Suit/Apt Number', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <MultipleSelect
                                isRequired={false}
                                label={"Region"}
                                multiple={false}
                                options={RegionOptions}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"region"}
                                onChange={(e) => handleInputChange({ target: { name: "region", value: e } })}
                                value={"value"}
                                selectedvalues={region}
                                error={patientError?.region}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <MultipleSelect
                                options={countryListData}
                                label={"Country"}
                                multiple={false}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"country"}
                                onChange={(e) => {
                                    getState(e[0]?.id)
                                    handleInputChange({ target: { name: "country", value: e } })
                                }}
                                value={"id"}
                                selectedvalues={country}
                            // error={providerQueryError?.addressCountry1}
                            />

                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                            <MultipleSelect
                                options={stateListData}
                                label={"State"}
                                multiple={false}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"state"}
                                onChange={(e) => {
                                    getCity(country[0]?.id, e[0]?.id)
                                    handleInputChange({ target: { name: "state", value: e } })
                                }}
                                value={"id"}
                                selectedvalues={state}
                            // error={clinicAddressError?.addressState1}
                            />
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <MultipleSelect
                                options={cityListData}
                                label={"City"}
                                multiple={false}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"city"}
                                onChange={(e) => handleInputChange({ target: { name: "city", value: e } })}
                                value={"id"}
                                selectedvalues={city}
                            // error={clinicAddressError?.city}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'zipcode'}
                                value={zipcode}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.zipcode}
                                data={{ label: 'Zip Code', placeholder: '', type: 'text' }} />
                        </div>
                    </div>
                </div>
                {/* Address Information */}

                {/* Contact Information */}
                <div className="col-lg-12 col-12 mt-4">
                    <div className='d-flex justify-content-center shadow-sm bgLightBlue1 font-14 fw-5 text-white p-1'>Contact Information</div>
                    <div className='row'>
                        <div className="col-sm-2 col-12 ">
                            <FormControl className='mt-4'>
                                <FormLabel className='fw-5 font-11 text-nowrap black fw-6'>Preferred Contact Method <span className='Star_Mark text-danger'>*</span></FormLabel>
                                <RadioGroup
                                    row
                                    // onChange={(e) => handleInputChange({ target: { name: "preferredContactMethod", value: e } })}
                                    onChange={handleInputChange}
                                    name='preferredContactMethod'
                                    value={preferredContactMethod}
                                >
                                    <FormControlLabel className='font-13' value="EMAIL" control={<Radio size="small" />} label="Email" />
                                    <FormControlLabel className='font-13' value="PHONE" control={<Radio size="small" />} label="Phone" />
                                    {/* <FormControlLabel className='font-13' value="both" control={<Radio  size="small" />} label="Both" /> */}
                                </RadioGroup>
                                {patientError?.preferredContactMethod && <span className="font-11" style={{ color: 'red' }}>{patientError?.preferredContactMethod}</span>}
                            </FormControl>
                        </div>
                        <div className="col-sm-3 col-12 mt-2">
                            <FormField
                                disabled={isEdit}
                                required={true}
                                // maxLength={50}
                                name={'email'}
                                value={email}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                error={patientError?.email}
                                data={{ label: 'Email Address', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6  col-12 mobile_number_input_box mt-3">
                            <label className="form-label mb-1 font-11 fw-6" >Phone<span className='Star_Mark text-danger'>*</span></label>
                            <div className="input_group_mobile mb-0">
                                <PhoneNumber
                                    width={false}
                                    Require={true}
                                    Name="phone"
                                    HandleChange={handleInputChange}
                                    Value={phone}
                                    disabledInput={false}
                                    error={patientError?.phone}
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-6  col-12 mobile_number_input_box mt-3">
                            <label className="form-label mb-1 font-11 fw-6" >Work Phone</label>
                            <div className="input_group_mobile mb-0">
                                <PhoneNumber
                                    width={false}
                                    Require={false}
                                    Name="workPhone"
                                    HandleChange={handleInputChange}
                                    Value={workPhone}
                                    disabledInput={false}
                                    error={patientError?.workPhone}
                                />
                            </div>
                        </div>

                        <div className=" col-sm-4 col-12 mt-2">
                            <FormField
                                maxLength={50}
                                name={'emergencyContactPersonName'}
                                value={emergencyContactPersonName}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.emergencyContactPersonName}
                                data={{ label: 'Emergency Contact Name', placeholder: '', type: 'text' }} />
                        </div>
                        <div className="col-sm-4 col-12 mt-2">
                            <MultipleSelect
                                isRequired={false}
                                label={"Relationship With Patient"}
                                multiple={false}
                                options={relationShipArr}
                                searchBy={"label"}
                                keyName={"label"}
                                name={"relationshipWithPatient"}
                                onChange={(e) => handleInputChange({ target: { name: "relationshipWithPatient", value: e } })}
                                value={"value"}
                                selectedvalues={relationshipWithPatient}
                                error={patientError?.relationshipWithPatient}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-6  col-12 mobile_number_input_box mt-3">
                            <label className="form-label mb-1 font-11 fw-6" >Emergency Contact Phone</label>
                            <div className="input_group_mobile mb-0">
                                <PhoneNumber
                                    width={false}
                                    Require={false}
                                    Name="emergencyContactPersonPhoneNumber"
                                    HandleChange={handleInputChange}
                                    Value={emergencyContactPersonPhoneNumber}
                                    disabledInput={false}
                                    error={patientError?.emergencyContactPersonPhoneNumber}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Contact Information */}

                {/* Financial Information */}
                <div className="col-lg-6 col-12 my-4 mb-3">
                    <div className='d-flex justify-content-center shadow-sm bgLightBlue1 font-14 fw-5 text-white p-1'>Financial Information</div>
                    <div className='row pb-3'>
                        <div className="col-lg-6 col-sm-6 col-12 ">
                            <MultipleSelect
                                isRequired={false}
                                label={"Payment Mode"}
                                multiple={false}
                                options={PaymentOptions}
                                searchBy={"title"}
                                keyName={"title"}
                                name={"paymentMode"}
                                onChange={(e) => handleInputChange({ target: { name: "paymentMode", value: e } })}
                                value={"value"}
                                selectedvalues={paymentMode}
                            // error={patientError.paymentMode}
                            />
                        </div>

                        <div className="col-lg-6 col-sm-6 col-12 ">
                            <MultipleSelect
                                isRequired={false}
                                label={"Billing Type"}
                                multiple={false}
                                options={billingTypeList}
                                searchBy={"name"}
                                keyName={"name"}
                                name={"billingType"}
                                onChange={(e) => handleInputChange({ target: { name: "billingType", value: e } })}
                                value={"id"}
                                selectedvalues={billingType}
                            />
                        </div>
                    </div>
                </div>

                {/* Financial Information */}


                {/* Reference */}

                <div className="col-lg-6 col-12 mt-4">
                    <div className='d-flex justify-content-center shadow-sm bgLightBlue1 font-14 fw-5 text-white p-1'>Reference</div>
                    <div className='row'>
                        <div className="col-lg-6 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'referenceBy'}
                                value={referenceBy}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.referenceBy}
                                data={{ label: 'Reference By', placeholder: '', type: 'text' }} />
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'referenceTo'}
                                value={referenceTo}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.referenceTo}
                                data={{ label: 'Reference To', placeholder: '', type: 'text' }} />
                        </div>
                    </div>
                </div>

                {/* Reference */}

                {/* Other Information */}
                <div className="col-12 border-bottom">
                    <div className='d-flex justify-content-center shadow-sm bgLightBlue1 font-14 fw-5 text-white p-1'>Other Information</div>
                    <div className='row mb-3'>

                        <div className="col-lg-3 col-sm-6 col-12 ">
                            <div className='mt-3'>
                                <label className='d-block font-11 fw-6 pb-1'>Id Proof </label>
                                {!licenseImage ? <div className='bgGrey1 p-1 px-2 rounded-1'>
                                    <input type="file" ref={FileRef} className='p-0 font-11' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "license", setSpinner, setLicenseImage)} />
                                </div>
                                    : <div className='d-flex '>
                                        <Avatar alt="Travis Howard" sx={{ width: 40, height: 40 }}
                                            src={userLicenceURL + licenseImage}>
                                        </Avatar>
                                        <p className='text-danger border rounded px-1 cursor ms-1'
                                            onClick={() => addPatientModal?.type !== "ADD"
                                                ? removeFile({
                                                    type: "SOFTDELETE",
                                                    getImages: driversLicense,
                                                    allImage,
                                                    imagePath: "license",
                                                    setAllImage,
                                                    setImage: setLicenseImage,
                                                    Images: licenseImage
                                                })
                                                : removeFile({
                                                    type: "SUBMIT_HARD_DELETE",
                                                    getImages: driversLicense,
                                                    allImage,
                                                    imagePath: "license",
                                                    setAllImage,
                                                    setImage: setLicenseImage,
                                                    Images: licenseImage
                                                })
                                            }>
                                            x
                                        </p>
                                    </div>}
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'responsibleParty'}
                                value={responsibleParty}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.responsibleParty}
                                data={{ label: 'Responsible Party Name', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'language'}
                                value={language}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.language}
                                data={{ label: 'Language Fluency', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'policyHolder'}
                                value={policyHolder}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.policyHolder}
                                data={{ label: 'Policy Holder Name', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 ">
                            <FormField
                                maxLength={50}
                                name={'employmentType'}
                                value={employmentType}
                                // onBlur={(e) => handleBlur(e)}
                                onChange={(e) => handleInputChange(e)}
                                // error={clinicAddressError?.employmentType}
                                data={{ label: 'Employment Type', placeholder: '', type: 'text' }} />
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 ">
                            <MultipleSelect
                                isRequired={false}
                                label={"Preferred Provider"}
                                multiple={false}
                                options={providerListData}
                                searchBy={"firstName"}
                                keyName={"firstName"}
                                name={"preferredProvider"}
                                onChange={(e) => handleInputChange({ target: { name: "preferredProvider", value: e } })}
                                value={"providerId"}
                                selectedvalues={preferredProvider}
                            // error={patientError.preferredProvider}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12 ">
                            <MultipleSelect
                                isRequired={false}
                                label={"Preferred Hygienist"}
                                multiple={false}
                                options={HygienistListData}
                                searchBy={"firstName"}
                                keyName={"firstName"}
                                name={"preferredHygienist"}
                                onChange={(e) => handleInputChange({ target: { name: "preferredHygienist", value: e } })}
                                value={"providerId"}
                                selectedvalues={preferredHygienist}
                            // error={patientError.preferredHygienist}
                            />
                        </div>
                    </div>
                </div>
                {/* Other Information */}
            </div>
            <div className='d-flex mt-4 justify-content-end'>
                <div >  <button className='theme-btn bgLightGray px-30' onClick={() => {
                    removeFile({
                        type: "DISCARD_HARD_DELETE", getImages: driversLicense, allImage, imagePath: "license", setAllImage, setImage: setLicenseImage,
                        Images: licenseImage
                    });
                    setAddPatientModal({ open: false });
                }}>Discard</button> </div>
                {addPatientModal?.type !== "ADD" ? <div className='ms-3 '>  <button className='theme-btn bgDarkBlue3 px-30' onClick={handleUpdateSubmit}>Update</button> </div> : <div className='ms-3 '>  <button className='theme-btn bgDarkBlue3 px-30' onClick={(e) => handleAddSubmit(e, "PATIENT")}>Submit</button> </div>}
            </div>
        </div >
    )
}

export default AddPatient