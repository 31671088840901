
import { axiosInstance, baseUrl, handleErrors } from "./auth";


//    clinic  details
export const CLINIC_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/clinic/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   update clinic 
export const UPDATE_CLINIC = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/clinic`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//Get clinic/notificaiton/settings
export const GET_NOTIFICATION_SETTING = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/clinic/notificaiton/settings`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// UPDATE_NOTIFICATION_SETTING
export const UPDATE_NOTIFICATION_SETTING = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/clinic/notificaiton/settings`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// clinic subscriptionPlan/list
export const GET_SUBSCRIPTION_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/clinic/subscriptionPlan/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// switch to provider AND CLINIC
export const SWITCHTOPROVIDERANDCLINIC = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/switch`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// Add clinicQuery || Location
export const ADD_CLINIC_LOCATION_QUERY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/clinicQuery`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_QUERY_LIST
export const GET_QUERY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/clinicQuery/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_QUERY_DETAILS
export const GET_QUERY_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/clinicQuery/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// UPDATE_QUERY
export const UPDATE_QUERY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/clinicQuery`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// Clinic Location List
export const GET_CLINIC_LOCATION_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/clinicLocation/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_CLINIC_LOCATION_DETAILS
export const GET_CLINIC_LOCATION_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/clinicLocation/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// UPDATE_CLINIC_LOCATION
export const UPDATE_CLINIC_LOCATION = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/clinicLocation`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// SPECIALITY_LIST
export const SPECIALITY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/speciality/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// Add PROVIDER QUERY
export const ADD_PROVIDER_QUERY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/providerQuery`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PROVIDER_QUERY_LIST
export const GET_PROVIDER_QUERY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/providerQuery/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// GET_PROVIDER_QUERY_DETAILS
export const GET_PROVIDER_QUERY_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/providerQuery/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// UPDATE_PROVIDER_QUERY
export const UPDATE_PROVIDER_QUERY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/providerQuery`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// CLINIC_SET_AS_DEFAULT
export const CLINIC_SET_AS_DEFAULT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/clinicLocation/setAsDefault`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}