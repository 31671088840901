import React from 'react'
import TableHeadings from '../../Components/common-components/table-headings'
import Modal from "../../Components/PopUpModal";
import FormField from '../../Components/common-components/form-input';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import AddIcon from '@mui/icons-material/Add';
import AddPatient from './AddPatient';
import { Menu, MenuItem } from '@mui/material';
import PrimaryAccount from './PrimaryAccount';
import { FaCodeMerge } from "react-icons/fa6";
import { HiDuplicate } from "react-icons/hi";
import { FaUserFriends } from "react-icons/fa";
import { Permission } from '../../Utils';
import { Permission_Message } from '../../Components/common-components/permissionMessage';
import PaginationCom from '../../Components/Pagination';

const SelectPatient = ({ data, methods }) => {
  const { patientModal, mergeAccountModal, patientListData, selectedMergeOptions, mergeType, searchText, totalCount, controller, patientSinkModal } = data
  const { setAddPatientModal, setMergeAccountModal, selectedPatient, getMergePatientList, clearSelectedMerge, reset, setPatientSinkModal,
    applayFilter, setSearchText, setController,sinkPatient ,clearForm} = methods
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const TableHeadData = [
    { label: '#' },
    { label: 'Name' },
    { label: 'Patient ID' },
    { label: 'Gender' },
    { label: 'Phone' },
    { label: 'Work Phone' },
    { label: 'Email Address' }
  ]

  const Search_Permission = Permission({ permission: 'Patient Management-Able to search patient' })
  const Add_Permission = Permission({ permission: 'Patient Management-Able to add patient' })
  const View_Permission = Permission({ permission: 'Patient Management-Able to view patient' })
  const Merge_Duplicate_Family_Permission = Permission({ permission: 'Patient Management-Able to merge duplicate account' })
  const Merge_Family_Permission = Permission({ permission: 'Patient Management-Able to merge as a family' })
  return (
    <div className='pb-3'>
      <div >
        <div className="d-lg-flex align-items-center px-3 pb-3">
          {
            Search_Permission &&
            <div className='mx-2 mx-sm-0 mt-2'>
              <FormField
                value={searchText}
                name={"searchText"}
                onChange={(e) => setSearchText(e.target.value)}
                data={{ placeholder: 'Search', rightIcon: '', type: 'text' }} />
            </div>
          }
          <div className="d-flex mt-3">
            {
              Search_Permission &&
              <>
                <button className="theme-btn bgBlue ms-2 ms-sm-0 ms-lg-3" onClick={() => applayFilter()}> <SearchIcon style={{ height: "20px" }} /> Search</button>
                <button className="theme-btn bgBlue mx-3" onClick={() => reset()}> <RestartAltIcon style={{ height: "20px" }} /> Reset</button>
              </>
            }
            {Add_Permission && <button className="theme-btn bgLightGreen2" onClick={() => { clearForm(); setAddPatientModal((prev) => ({ ...prev, open: true, type: "ADD" })) }}> <AddIcon style={{ height: "20px" }} /> Add New</button>}
            {
              (Merge_Duplicate_Family_Permission || Merge_Family_Permission) &&
              <button className="theme-btn bgLightGreen2 ms-3" onClick={handleClick}> <FaCodeMerge className='me-2' style={{ height: "20px" }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} /> Merge Account </button>
            }
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            // anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
            >
              {Merge_Duplicate_Family_Permission && <MenuItem > <span className='font-12 ms-1 fw-5' onClick={() => { getMergePatientList(); setMergeAccountModal((prev) => ({ ...prev, type: mergeType.duplicate, open: true })) }}> <HiDuplicate className='font-18 black1' /> Merge as Duplicate </span> </MenuItem>}

              {Merge_Family_Permission && <MenuItem > <span className='font-12 ms-1 fw-5' onClick={() => { getMergePatientList(); setMergeAccountModal((prev) => ({ ...prev, type: mergeType.familyMember, open: true })) }}> <FaUserFriends className='font-18 black1' /> Merge as Family Member</span> </MenuItem>}
              {/* {Merge_Duplicate_Family_Permission &&
                <MenuItem > <span className='font-12 ms-1 fw-5' onClick={() => { setPrimaryAccountModal((prev) => ({ ...prev, type: type.duplicate, open: true })) }}> <HiDuplicate className='font-18 black1' /> Merge as Duplicate </span> </MenuItem>
              }
              {Merge_Family_Permission &&
                <MenuItem > <span className='font-12 ms-1 fw-5' onClick={() => { setPrimaryAccountModal((prev) => ({ ...prev, type: type.familyMember, open: true })) }}> <FaUserFriends className='font-18 black1' /> Merge as Family Member</span> </MenuItem>
              } */}

            </Menu>
            <button className="theme-btn bgLightGreen2 ms-2" onClick={()=>setPatientSinkModal({ data: {}, open: true }) }> <AddIcon style={{ height: "20px" }} />Sync to sota cloud</button>
          </div>
        </div>
        {
          View_Permission ?
            <>
              <table className="customTableTeamManagment">
                <TableHeadings className={'p-1 text-center bgGrey2 black fw-6 font-13'} TableHeadData={TableHeadData} />
                <tbody>
                  {patientListData?.map((res, index) => (
                    <tr key={index} className='text-center'>
                      <td > {controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                      <td className='blue1 cursor text-center' onClick={() => selectedPatient(res)}>{`${res?.firstName ? res?.firstName : "-"} ${res?.lastName ? res?.lastName : "-"}`}</td>
                      <td className='text-center'>{res?.patientId ? res?.patientId : '--'}</td>
                      <td className='text-center'>{res?.gender ? res?.gender : '--'}</td>
                      <td className='text-center'>{res?.phone ? res?.phone : '--'}</td>
                      <td className='text-center'>{res?.workPhone ? res?.workPhone : '--'}</td>
                      <td className='text-center'>{res?.email ? res?.email : '--'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!patientListData?.length && <div className="text-center mt-2">No record found</div>}
              <div className="d-flex justify-content-center mt-2">
                {
                  totalCount > 10 && <PaginationCom count={totalCount} controller={controller} setController={setController} />
                }
              </div>
            </>
            : Permission_Message
        }
      </div>

      {/* patient modal */}
      <Modal className="p-0"
        size="xl" show={patientModal?.open} onHide={() => {
          setAddPatientModal({ data: null, open: false })
          // clearForm()
          // setStateError({});
        }}
        headerContent={<h5 className="py-2 mb-0">Add Patient</h5>}
        bodyContent={
          <AddPatient data={data} methods={methods} />
        }
      />

      {/* primary account Modal*/}
      <Modal className="p-0" HeaderClass={'p-0'}
        size="xl" show={mergeAccountModal?.open} onHide={() => {
          setMergeAccountModal({ data: {}, open: false });
          clearSelectedMerge()
          // clearForm()
          // setStateError({});
        }}
        headerContent={<> <div className='w-100 bgDarkBlue2 text-white p-3 text-center font-14 fw-6'>{!selectedMergeOptions.primary ? "Select Primary Account" : "Select secondary Account"}</div> </>}
        bodyContent={
          <PrimaryAccount data={data} methods={methods} />
        }
      />
      {/* Sink Patient Modal */}
      <Modal
        size="md" headerBorderBottom={true} show={patientSinkModal?.open} onHide={() => { setPatientSinkModal({ data: {}, open: false }) }}
        bodyContent={
          <div >
            <div className="text-center col-12">
              <div className='mt-3 mb-4'>
                {/* <DeleteIcon className='cursor red' sx={{ fontSize: '50px' }} /> */}
              </div>
              <div className="grey-color font-15 my-2">
                {/* <span> {deleteModal?.data?.description} </span> */}
                Are you sure? <br /> You want to sync patient to sota cloud.
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="d-flex justify-content-center">
                <div>
                  <button type="submit" className="theme-btn black1 col-11 mt-4 px-5" onClick={() => setPatientSinkModal({ data: {}, open: false })}> Discard</button>
                </div>

                <div>
                  <button type="button" className="theme-btn bgDarkBlue col-11 mt-4 px-5" onClick={sinkPatient}>Submit</button>
                </div>

              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default SelectPatient