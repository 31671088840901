import { axiosInstance, baseUrl, handleErrors } from "./auth";


export const BOOK_APPOINTMENT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/book`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const EDIT_APPOINTMENT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/edit`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const EDIT_WAITING_APPOINTMENT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/waiting/edit`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const GET_APPOINTMENT_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/appointment/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const GET_SCHEDULER_DATA = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/schedularData`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const APPOINTMENT_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const APPOINTMENT_WAITING_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/waitingList/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const UPDATE_PRIVACYMODE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/updatePrivacyMode`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


export const GET_PRIVACYMODE = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/user/getPrivacyMode`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const GET_PROVIDER_HYGIENIST_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/providerAndHygienistList`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const CHANGE_APPOINTMENT_STATUS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/changeStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const DELETE_APPOINTMENT = async (params) => {
    try {
        const response = await axiosInstance.delete(`${baseUrl}/appointment/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const MARK_AS_NOSHOW = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/markAsNoShow`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const ADD_BLOCKOUT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/bookBlock`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const EDIT_BLOCKOUT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/appointment/editBlock`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const GROUP_TEATMENT_PLAN_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/treatmentplan/groupTreatmentPlan`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

