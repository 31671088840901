import React from 'react';
import { useParams } from 'react-router-dom';

const IframePage = () => {
  const { encodedUrl } = useParams(); // URL is passed as a param
  const decodedUrl = decodeURIComponent(encodedUrl); // Decode the URL

  return (
    <div>
      <iframe
        src={decodedUrl}
        width="100%"
        height="800px"
        title="X-Ray Viewer"
        frameBorder="0"
      />
    </div>
  );
};

export default IframePage;
