import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { PROCEDURE_CATEGORY_LIST, PROCEDURE_CATEGORY_STATUS_CHANGE, ADD_PROCEDURE_CATEGORY, UPDATE_PROCEDURE_CATEGORY, DELETE_PROCEDURE_CATEGORY } from '../../../../repository/setup'
import useReduxStore from '../../../../hooks/useReduxStore'
import { adjustPageAfterDeletion, requiredMessage } from '../../../../Utils'
import useUploadImage from '../../../../hooks/useUploadImage'
import { modalEnum } from '../../../../constant'

const UseCategory = () => {
    const { UploadImage } = useUploadImage()
    const FileRef = useRef()
    const { selector: user } = useReduxStore("auth")
    const [spinner, setSpinner] = useState(false)
    const [totalCount, settotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: 1 })
    const [categoryList, setCategoryList] = useState([])
    const [filter, setFilter] = useState({ search: "" })
    const [categoryImage, setCategoryImage] = useState(null)

    // -------------Form Section------------------
    const [categoryInputs, setCategoryInputs] = useState({
        name: "",
        procedureCategoryImage: ""
    })

    const [categoryError, setCategoryError] = useState({})
    const [categoryModal, setCategoryModal] = useState(modalEnum)
    const [deleteModal, setDeleteModal] = useState(modalEnum)

    const clearForm = () => {
        setCategoryInputs(
            {
                name: "",
            }
        )
        setCategoryError({})
    }

    useEffect(() => {
        getCategoryListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])

    const getCategoryListData = async () => {
        try {
            let payload = {
                count: controller.rowsPerPage,
                page: controller.page,
                searchName: filter?.search,
                isDeleted: false,
                clinicId: user?.clinic_id,
            }
            setSpinner(true)
            let response = await PROCEDURE_CATEGORY_LIST(payload)
            setCategoryList(response?.data)
            settotalCount(response.count)
        } catch (error) {
            toast.error(error?.message)
            setCategoryList([])
            settotalCount(0)
        }
        setSpinner(false)
    }

    // apply Filter
    const ApplyFilter = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    // Reset Filter
    const resetFilter = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage, searchPage: 1 }))
        setFilter({ search: "" })
    }

    // Status toggle method
    const status = async (data, toggle = null) => {
        setSpinner(true)
        try {
            let payload = {
                id: data?.id,
                isActive: toggle
            }
            let response = await PROCEDURE_CATEGORY_STATUS_CHANGE(payload)
            getCategoryListData()
            clearForm()
            toast.success(response?.message)
        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }


    // ---------------------Form Functnality-----------------------------

    // handle Input
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // const trimmedValue = (value || '').toString().trim();
        setCategoryInputs((prevProps) => ({ ...prevProps, [name]: value }));

        // trimmedValue.length &&
        setCategoryError((pre) => {
            delete pre[name];
            return { ...pre };
        });
    };

    const handleBlur = (event) => {
        const { name, value } = event.target
        !value?.trim()?.length && setCategoryError((pre) => ({ ...pre, [name]: errorMessage[name] }))
    }

    // Error Message
    const errorMessage = {
        name: requiredMessage('name'),
    }

    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!categoryInputs?.name) {
            errorObj['name'] = errorMessage['name']
        }
        setCategoryError(errorObj)
        return !Object.keys(errorObj)?.length
    }

    // Handle add submit data..!
    const handleAddSubmit = async (event) => {
        event.preventDefault()
        if (!isValid()) return
        setSpinner(true)
        let payload = {
            clinicId: user.clinic_id,
            name: categoryInputs?.name,
            image: categoryImage,
        }
        try {
            let response = await ADD_PROCEDURE_CATEGORY(payload)
            setCategoryModal(modalEnum)
            clearForm()
            getCategoryListData()
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        } catch (err) {
            toast.error(err.message)
            setSpinner(true)
        }
        setSpinner(false)
    }

    // Handle Update submit data..!
    const handleupdateSubmit = async (event) => {
        event.preventDefault()
        if (!isValid()) return
        setSpinner(true)
        try {
            let payload = {
                id: categoryModal?.data.id,
                clinicId: user.clinic_id,
                isActive: categoryModal?.data.isActive,
                name: categoryInputs?.name,
            }
            let response = await UPDATE_PROCEDURE_CATEGORY(payload)
            setCategoryModal(modalEnum)
            clearForm()
            getCategoryListData()
            toast.success(response.message)
        } catch (err) {
            toast.error(err.message)
            setSpinner(true)
        }
        setSpinner(false)
    }

    // Handle delete category..!
    const handleDelete = async (event) => {
        setSpinner(true)
        let payload = {
            id: deleteModal?.data?.id,
            isDeleted: true
        }
        try {
            let response = await DELETE_PROCEDURE_CATEGORY(payload)
            setDeleteModal(modalEnum)
            adjustPageAfterDeletion({
                totalCount: totalCount,
                currentPage: controller.page,
                rowsPerPage: controller.rowsPerPage,
                onPageChange: (newPage) => setController((prev) => ({ ...prev, page: newPage })),
                onTotalCountChange: (newTotal) => settotalCount(newTotal),
            });
            await getCategoryListData()
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }

    return {
        data: {
            spinner,
            controller,
            totalCount,
            categoryList,
            filter,
            deleteModal,
            FileRef,
            categoryImage,
            // ---------------Form data
            categoryModal,
            categoryInputs,
            categoryError,
            Permission: user
        },
        methods: {
            settotalCount,
            setController,
            setCategoryList,
            setCategoryInputs,
            setCategoryError,
            status,
            setFilter,
            ApplyFilter,
            resetFilter,
            setDeleteModal,
            setCategoryImage,
            // -----------------------Form methods ---------------
            setCategoryModal,
            handleInputChange,
            handleBlur,
            handleAddSubmit,
            handleupdateSubmit,
            handleDelete,
            UploadImage,
            setSpinner,
            clearForm
        }
    }
}

export default UseCategory


