import React from 'react';
import forOfor from "../../Assets/images/404.gif"
import { useNavigate } from 'react-router-dom';
import useReduxStore from '../../hooks/useReduxStore';
const Page404 = () => {
    const navigate = useNavigate()
    const { selector: user } = useReduxStore("auth")

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-5 d-flex align-items-center pt-5">
                    <div>
                        <div className="col-lg-12">
                            <h1 style={{ color: '#4183DA', fontSize: '4.5rem' }}>
                                <b>Page <br /> not found!..</b>
                            </h1>
                        </div>
                        <div className="col-lg-12">
                            <br />
                            <h3>Sorry, the page was not found</h3>
                            <p>
                                The link you followed is probably broken or <br /> the page has been removed.
                            </p>
                        </div>

                        <div class="mt-5 d-flex">
                            {user?.token && <button class="btn btn-primary" onClick={() => navigate(-1)}>Go Back</button>}
                            <button class="btn btn-primary ms-4" onClick={() => navigate("/")}>Go Back Home</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 pt-5 d-flex align-items-center">
                    <img src={forOfor} className="img-fluid mt-5" alt="404 Not Found" />
                </div>
            </div>
        </div>
    );
};

export default Page404;
