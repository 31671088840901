import { useNavigate } from 'react-router-dom';
const useBroserNotification = () => {
    const navigate = useNavigate()
    const handleCleckNotification = (data) => {
        const notification = new Notification(data?.title ?? "", {
            body: data?.message ?? "",
        });
        notification.onclick = () => {
            if (data?.routes) {
                navigate(data?.routes)
            }
        };
    }
    const showNotification = (data) => {
        if ('Notification' in window) {
            if (Notification.permission === 'granted') {
                handleCleckNotification(data)
            } else if (Notification.permission !== 'granted') {

                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        handleCleckNotification(data)
                    }
                });
            }
        }
    };
    return {
        showNotification
    }
}

export default useBroserNotification
