/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import useReduxStore from '../../../hooks/useReduxStore'
import { PERIO_CHART, TIME_LIST, CHART_LIST, PERIO_CHART_UPDATE, REMOVE_TEETH, AUTO_SAVE_TOGGLE, GET_AUTO_SAVE_STATUS } from '../../../repository/charting'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import { useReactToPrint } from 'react-to-print';
import { dentitionAgeArray, modalEnum, toothObj } from '../../../constant'
import { NotificationsState } from '../../../App'
import {
    chartNotAssigned, chartsText, chart_Names, Assigned_Chart_Names, teeths, teethsReverse, teethToHideForValue5, teethToHideForValue2,
    teethToHideForValue3, teethToHideForValue4, teethReverseForValue5, teethForValue5
} from './constant'


const UsePerioChart = () => {
    const [selectInput, setSelectInput] = useState('')
    const [selectedChart, setSelectedChart] = useState(null);
    const [chartSettingModal, setChartSettingModal] = useState(modalEnum);
    const [chartAddModal, setChartAddModal] = useState(modalEnum);
    const [allChart, setAllChart] = useState(chartNotAssigned)
    const [assignedPermissions, setAssignedPermissions] = useState(chartsText)
    const [selectedAllPermissions, setSelectedAllPermissions] = useState([]);
    const [selectedAssignedPermissions, setSelectedAssignedPermissions] = useState([]);
    const [draggedItem, setDraggedItem] = useState(null);
    const [draggedList, setDraggedList] = useState(null);
    const [assignedChart, setAssignedChart] = useState(Assigned_Chart_Names);
    const [chartPosition, setChartPosition] = useState('leftToRight');
    const [disabled, setDisabled] = useState(false);
    const { selector: user } = useReduxStore("auth")
    const { selector: PatientData } = useReduxStore("PatientData")
    const [spinner, setSpinner] = useState(false)
    const [timeListData, setTimeListData] = useState([])
    const [selectedTime, setSelectedTime] = useState('')
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [perioUpdate, setPerioUpdate] = useState({
        update: true,
        id: '',
    })
    const [routingData, setRoutingData] = useState({
        date: '',
        id: ''
    })
    const [missingTeeths, setMissingTeeths] = useState([])
    const [perioAutoSaveData, setPerioAutoSaveData] = useState([])
    const [autoSave, setAutoSave] = useState(true)
    const [isManualUpdate, setIsManualUpdate] = useState(false);
    const { setPerioData, setValue, setMissTeeth } = useContext(NotificationsState)
    const [dentitionAge, setDentitionAge] = useState([dentitionAgeArray[dentitionAgeArray?.length - 1]])
    const [isSettingDentitionAge, setIsSettingDentitionAge] = useState(false);
    const createToothArray = (reverse) => {
        const arr = [];
        const labels = (dentitionAge?.[0]?.value < 6 || dentitionAge?.[0]?.value < 7)
            ? (reverse
                ? teethsReverse
                : teeths)
            : (reverse
                ? Array.from({ length: 16 }, (_, i) => String(32 - i))
                : Array.from({ length: 16 }, (_, i) => String(i + 1)));

        // Create tooth objects based on the determined labels
        labels.forEach(label => {
            const tooth = {
                ...toothObj,
                tooth_no: label,
                isShowTooth: true,
                cal: {
                    area_1: (toothObj.probing_depth?.area_1 || 0) + (toothObj.gingival_margin.area_1 || 0),
                    area_2: (toothObj.probing_depth?.area_2 || 0) + (toothObj.gingival_margin.area_2 || 0),
                    area_3: (toothObj.probing_depth?.area_3 || 0) + (toothObj.gingival_margin.area_3 || 0),
                }
            };
            arr.push(tooth);
        });

        return arr;
    };
    const [perioChartForm, setPerioChartForm] = useState([
        {
            type: "Upper",
            buccal: createToothArray(false),
            lingual: createToothArray(false)
        },
        {
            type: "Lower",
            lingual: createToothArray(true),
            buccal: createToothArray(true)
        }
    ]);
    const [focusedInput, setFocusedInput] = useState({});
    const [values, setValues] = useState({
        mobility: 1,
        horizontal_probing: 1,
        gingival_margin: 1,
        probing_depth: 1,
    });
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [focusChange, setFocusChange] = useState('');
    const inputRefs = useRef({});
    // console.log(perioChartForm, 'perioChartForm')

    useEffect(() => {
        timeList()
        remove()
        getAutoSave()
    }, [])

    useEffect(() => {
        if (values) {
            setValue(values)
        }

    }, [values])

    useEffect(() => {
        if (autoSave && !perioUpdate?.update) {
            if (isFirstRender) {
                setIsFirstRender(false); // Set flag to false after first render
                return; // Skip the first save
            }
            // If a manual update was triggered, skip the auto-save
            if (isManualUpdate) {
                return;
            }
            const handler = setTimeout(() => {
                if (perioUpdate?.update) {
                    UpdatePerioChart();
                } else {
                    setPerioChart();
                }
            }, 5000);
            return () => {
                clearTimeout(handler); // Clear timeout if input changes within the delay
            };
        }
    }, [perioAutoSaveData, isManualUpdate]);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false); // Set flag to false after first render
            return; // Skip the first save
        }
        const handler = setTimeout(() => {
            if (chartPosition === 'rightToLeft') {
                handleFocusChange('left');
            }
            if (chartPosition === 'leftToRight') {
                handleFocusChange('right');
            }
        }, 10);
        return () => {
            clearTimeout(handler); // Clear timeout if input changes within the delay 
        };

    }, [focusChange]);

    const capitalizeField = (field = null) => {
        return field
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char?.toUpperCase());
    };
    const handleChange = (e, type, orientation, toothNo, field) => {
        const value = +e.target.value; // Ensure the value is a number
        setFocusChange(Math.random(e.target.value));
        setPerioChartForm((prevState) => {
            const updatedForm = [...prevState];
            const chartIndex = updatedForm?.findIndex((chart) => chart?.type === type);
            const toothIndex = updatedForm?.[chartIndex]?.[orientation]?.findIndex(
                (tooth) => tooth?.tooth_no === toothNo
            );

            if (value.length === 1 && toothIndex < inputRefs?.current.length - 1) {
                inputRefs?.current[toothIndex + 1].focus();
            }
            const [mainField, subField] = field?.split('.');
            if (subField !== undefined) {
                updatedForm[chartIndex][orientation][toothIndex][mainField] = {
                    ...updatedForm[chartIndex][orientation][toothIndex][mainField],
                    [subField]: value
                };
            } else {
                updatedForm[chartIndex][orientation][toothIndex][mainField] = value;
            }
            // Recalculate the cal value
            const gingivalMargin = updatedForm[chartIndex][orientation][toothIndex]?.gingival_margin?.[subField] || 0;
            const probingDepth = updatedForm[chartIndex][orientation][toothIndex]?.probing_depth?.[subField] || 0;
            updatedForm[chartIndex][orientation][toothIndex].cal[subField] = gingivalMargin + probingDepth;
            setPerioAutoSaveData(updatedForm); // Call auto-save functionality
            return updatedForm;
        });
    };
    const handleChangeCheckbox = (e, type = '', orientation = '', toothNo = null, field = '') => {
        const { value, checked, type: inputType } = e.target;
        const fieldValue = inputType === 'checkbox' ? checked : Number(value);

        setPerioChartForm((prevState) => {
            const updatedForm = [...prevState];
            const chartIndex = updatedForm?.findIndex((chart) => chart.type === type);
            const toothIndex = updatedForm[chartIndex][orientation]?.findIndex(
                (tooth) => tooth.tooth_no === toothNo
            );
            const [mainField, subField] = field.split('.');
            if (subField !== undefined) {
                // Update the nested field for gingival_margin and probing_depth
                updatedForm[chartIndex][orientation][toothIndex][mainField] = {
                    ...updatedForm[chartIndex][orientation][toothIndex][mainField],
                    [subField]: fieldValue
                };
            } else {
                // Otherwise, update the main field directly
                updatedForm[chartIndex][orientation][toothIndex][mainField] = fieldValue;
            }
            setPerioAutoSaveData(updatedForm)
            return updatedForm;
        });
    };
    const handleButtonClick = (chart, index) => {
        setSelectedChart(chart);
        setSelectInput(index);
    };
    const getAreas = (type, orientation, field) => {
        if (field === "furcation") {
            return type === "Upper" && orientation === "lingual" ? ["area_1", "area_2"] : ["area_1"];
        }
        return ["area_1", "area_2", "area_3"];
    };
    const renderRows = (type, orientation) => {
        const fields = assignedChart;
        const disabledTeeth = missingTeeths; // Teeth to disable
        const getBackgroundColor = (field, isRowSelected, isDisabled) => {
            if (isDisabled) return '#ECECEC';
            if (["suppuration", "bleeding_on_probing", "calculus", "plaque"].includes(field)) return isRowSelected ? '#86DE87' : '';
            return isRowSelected ? '#86DE87' : '';
        };

        const getColor = (field, isRowSelected, isDisabled, value) => {
            if (isDisabled) return '#a39b9bbd';
            if (["gingival_margin", "probing_depth"].includes(field) && value >= values?.[field]) return 'red';
            return isRowSelected ? 'white' : 'black';
        };

        return fields?.map((field) => {
            if (field === 'isShowTooth') return null;
            const isRowSelected = selectedChart === field;
            return (
                <tr key={`${type}-${orientation}-${field}`} style={{
                    // backgroundColor: disabled ? '#ECECEC' : ["suppuration", "bleeding_on_probing", "calculus", "plaque"]?.includes(field) ? null : isRowSelected ? '#86DE87' : '',
                    backgroundColor: getBackgroundColor(field, isRowSelected, disabled),
                    // color: disabled ? '#a39b9bbd' : ["suppuration", "bleeding_on_probing", "calculus", "plaque"]?.includes(field) ? null : isRowSelected ? 'white' : ''
                    color: getColor(field, isRowSelected, disabled)
                }}>
                    <td className='perio_Font_Size' style={{
                        backgroundColor: ["suppuration", "bleeding_on_probing", "calculus", "plaque"]?.includes(field) ? isRowSelected ? '#86DE87' : '' : null,
                        color: ["suppuration", "bleeding_on_probing", "calculus", "plaque"]?.includes(field) ? isRowSelected ? 'white' : '' : null,
                    }}>{field === 'gingival_margin' ? 'Recession' : capitalizeField(field)}</td>
                    {perioChartForm?.find((chart) => chart.type === type)[orientation]?.map((tooth) => {
                        const isToothDisabled = disabledTeeth?.includes(tooth.tooth_no) || !tooth.isShowTooth;
                        return (
                            <td key={`tooth-${type}-${orientation}-${tooth.tooth_no}`} className='text-center chart_td_font' style={{
                                backgroundColor: isToothDisabled ? '#ECECEC' : '',
                                color: isToothDisabled ? '#a39b9bbd' : ''
                            }}>
                                {["suppuration", "bleeding_on_probing", "calculus", "plaque", "gingival_margin", "probing_depth", "furcation", 'cal']?.includes(field) ? (
                                    <div className={`${field}-wrapper d-flex`} style={{
                                        backgroundColor: isToothDisabled ? '#ECECEC' : '',
                                        color: isToothDisabled ? '#a39b9bbd' : ''
                                    }}>
                                        {getAreas(type, orientation, field)?.map((area, index) => (
                                            <div className={`checkbox d-flex w-100 ${(field === "furcation" && getAreas(type, orientation, field)?.length === 1) ? 'perioTable_Border2' :
                                                (getAreas(type, orientation, field)?.length === 2 ? index !== 1 : index !== 2) && 'perioTable_Border'}`} key={area}>
                                                {field === "gingival_margin" || field === "probing_depth" || field === "furcation" || field === "cal" ? (
                                                    <input
                                                        type="number"
                                                        value={tooth?.[field]?.[area] || ''}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            if (inputValue > 15) {
                                                                e.target.value = 15;
                                                            }
                                                            if (field === "furcation" && inputValue > 3) {
                                                                e.target.value = 3;
                                                            }

                                                            handleChange(e, type, orientation, tooth.tooth_no, `${field}.${area}`);
                                                        }}
                                                        className='text-center'
                                                        id={`input-${type}-${orientation}-${tooth.tooth_no}-${field}-${area}`}
                                                        maxLength={2}
                                                        onFocus={() => setFocusedInput({ type, orientation, toothNo: tooth.tooth_no, field: `${field}.${area}` })}
                                                        onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                        ref={(el) => {
                                                            if (!inputRefs.current) inputRefs.current = {};
                                                            if (!inputRefs.current[type]) inputRefs.current[type] = {};
                                                            if (!inputRefs.current[type][orientation]) inputRefs.current[type][orientation] = {};
                                                            if (!inputRefs.current[type][orientation][tooth.tooth_no]) inputRefs.current[type][orientation][tooth.tooth_no] = {};
                                                            inputRefs.current[type][orientation][tooth.tooth_no][`${field}.${area}`] = el;
                                                        }}
                                                        disabled={field === "cal" || disabled || isToothDisabled} // Disable input if tooth is in the disabled list
                                                        // style={{ color: (disabled || isToothDisabled) ? '#a39b9bbd' : null }}
                                                        style={{
                                                            color: (disabled || isToothDisabled) ? '#a39b9bbd' :
                                                                (["gingival_margin", "probing_depth"]?.includes(field) &&
                                                                    tooth?.[field]?.[area] >= values?.[field] ? 'red' : 'black')
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            checked={tooth?.[field]?.[area] || false}
                                                            onChange={(e) => handleChangeCheckbox(e, type, orientation, tooth.tooth_no, `${field}.${area}`)}
                                                            className={`${field === "suppuration" ? "input-suppuration" : ""} ${field === "calculus" ? "input-calculus" : ""} ${field === "plaque" ? "input-plaque" : ""} ${index === 2 ? 'input-assumpte2' : 'input-assumpte'}`}
                                                            id={`input-${type}-${orientation}-${tooth.tooth_no}-${field}-${area}`}
                                                            disabled={disabled || isToothDisabled}
                                                        />
                                                        <label
                                                            htmlFor={`input-${type}-${orientation}-${tooth.tooth_no}-${field}-${area}`}
                                                            className={`${field === "suppuration" ? "suppuration-checked" : ""} ${field === "calculus" ? "calculus-checked" : ""} ${field === "plaque" ? "plaque-checked" : ""} ${index === 2 ? 'assumpte2-checked' : 'assumpte-checked'}`}
                                                        ></label>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <input
                                        type="text"
                                        value={tooth?.[field] || ""}
                                        onChange={(e) => {
                                            let inputValue = e.target.value
                                            if (inputValue > 15) {
                                                e.target.value = 15
                                            }
                                            handleChange(e, type, orientation, tooth.tooth_no, field);
                                        }}
                                        className='text-center'
                                        maxLength={2}
                                        onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                        onFocus={() => setFocusedInput({ type, orientation, toothNo: tooth.tooth_no, field })}
                                        ref={(el) => {
                                            if (!type || !orientation || !tooth?.tooth_no || !field) return;
                                            if (el) {
                                                if (!inputRefs.current[type]) inputRefs.current[type] = {};
                                                if (!inputRefs.current[type][orientation]) inputRefs.current[type][orientation] = {};
                                                if (!inputRefs.current[type][orientation][tooth.tooth_no]) inputRefs.current[type][orientation][tooth.tooth_no] = {};

                                                inputRefs.current[type][orientation][tooth.tooth_no][field] = el;
                                            } else {
                                                if (
                                                    inputRefs.current?.[type]?.[orientation]?.[tooth.tooth_no]?.[field]
                                                ) {
                                                    delete inputRefs.current[type][orientation][tooth.tooth_no][field];
                                                }
                                            }
                                        }}
                                        disabled={disabled || isToothDisabled} // Disable input if tooth is in the disabled list
                                        // style={{ color: (disabled || isToothDisabled) ? '#a39b9bbd' : null }}
                                        style={{
                                            color: (disabled || isToothDisabled) ? '#a39b9bbd' : (["mobility", "horizontal_probing"]?.includes(field) &&
                                                tooth?.[field] >= values?.[field] ? 'red' : 'black')
                                        }}
                                    />
                                )}
                            </td>
                        )
                    })}
                </tr>
            )
        });
    };

    const handleFocusChange = (direction) => {
        if (!focusedInput.type || !focusedInput.orientation || !focusedInput.toothNo || !focusedInput.field) {
            return;
        }
        const { type, orientation, toothNo, field } = focusedInput;
        const typeChart = perioChartForm?.find((chart) => chart.type === type);

        if (!typeChart || !typeChart[orientation]) {
            return;
        }
        const toothIndex = typeChart[orientation]?.findIndex(tooth => tooth.tooth_no === toothNo);
        if (toothIndex === -1) {
            return;
        }

        // Get the list of teeth in the orientation
        const teeth = typeChart[orientation];

        // Define nested fields structure based on the type and orientation
        const nestedFields = {
            gingival_margin: ['area_1', 'area_2', 'area_3'],
            probing_depth: ['area_1', 'area_2', 'area_3'],
            furcation: (type === 'Upper' || orientation === 'lingual')
                ? ['area_1', 'area_2'] // Only two areas for "Upper" type or "lingual" orientation
                : ['area_1'], // Default structure for other types
            // Add other main fields with their subfields here if needed
        };

        // Split the field into mainField and subField
        const [mainField, subField] = field.split('.');

        if (subField && nestedFields[mainField]) {
            // Navigate within the nested fields
            const currentFieldIndex = nestedFields[mainField].indexOf(subField);
            let newFieldIndex = currentFieldIndex;

            if (direction === 'left' && currentFieldIndex > 0) {
                newFieldIndex = currentFieldIndex - 1;
            } else if (direction === 'right' && currentFieldIndex < nestedFields[mainField].length - 1) {
                newFieldIndex = currentFieldIndex + 1;
            } else {
                // Move to the next tooth if at the boundary
                let newToothIndex = toothIndex;
                if (direction === 'right' && currentFieldIndex === nestedFields[mainField].length - 1) {
                    // Move to the next tooth if it's not disabled
                    do {
                        newToothIndex += 1;
                    } while (newToothIndex < teeth.length && missingTeeths.includes(teeth[newToothIndex].tooth_no));

                    if (newToothIndex >= teeth.length) {
                        // If no more teeth are available, do not proceed further
                        return;
                    }
                } else if (direction === 'left' && currentFieldIndex === 0) {
                    // Move to the previous tooth if it's not disabled
                    do {
                        newToothIndex -= 1;
                    } while (newToothIndex >= 0 && missingTeeths.includes(teeth[newToothIndex].tooth_no));

                    if (newToothIndex < 0) {
                        // If no more teeth are available, do not proceed further
                        return;
                    }
                }

                const newTooth = teeth[newToothIndex];
                const newField = `${mainField}.${nestedFields[mainField][0]}`; // Reset to the first subfield

                setFocusedInput({
                    type,
                    orientation,
                    toothNo: newTooth.tooth_no,
                    field: newField
                });

                // Focus on the input element using inputRefs
                if (inputRefs.current[type]?.[orientation]?.[newTooth.tooth_no]?.[newField]) {
                    inputRefs.current[type][orientation][newTooth.tooth_no][newField].focus();
                }
                return;
            }

            const newField = `${mainField}.${nestedFields[mainField][newFieldIndex]}`;

            setFocusedInput({
                type,
                orientation,
                toothNo,
                field: newField
            });

            // Focus on the input element using inputRefs
            if (inputRefs.current[type]?.[orientation]?.[toothNo]?.[newField]) {
                inputRefs.current[type][orientation][toothNo][newField].focus();
            }
        } else {
            // Navigate between teeth
            let newIndex = toothIndex;

            if (direction === 'left') {
                do {
                    newIndex -= 1;
                } while (newIndex >= 0 && missingTeeths.includes(teeth[newIndex].tooth_no));
            } else if (direction === 'right') {
                do {
                    newIndex += 1;
                } while (newIndex < teeth.length && missingTeeths.includes(teeth[newIndex].tooth_no));
            }

            if (newIndex < 0 || newIndex >= teeth.length) {
                return;
            }

            const newTooth = teeth[newIndex];

            setFocusedInput({
                type,
                orientation,
                toothNo: newTooth.tooth_no,
                field
            });

            // Focus on the input element using inputRefs
            if (inputRefs.current[type]?.[orientation]?.[newTooth.tooth_no]?.[field]) {
                inputRefs.current[type][orientation][newTooth.tooth_no][field].focus();
            }
        }
    };
    const handleNumberType = (number) => {
        if (focusedInput) {
            const { type, orientation, toothNo, field } = focusedInput;
            handleChange({ target: { value: number?.toString() } }, type, orientation, toothNo, field);
        }
    }
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    const handleDrop = (event, listToUpdate) => {
        event.preventDefault();
        if (draggedList === listToUpdate) return;

        let updatedAllPermissions = [...allChart];
        let updatedAssignedPermissions = [...assignedPermissions];

        if (listToUpdate === 'assignedPermissions') {
            // Handle dropping into assigned permissions
            if (selectedAllPermissions.length > 0) {
                // Handle dropping multiple selected items from all permissions to assigned permissions
                selectedAllPermissions.forEach(selectedItem => {
                    updatedAllPermissions = updatedAllPermissions.filter(permission => permission !== selectedItem);
                    updatedAssignedPermissions.push(selectedItem);
                });
            } else {
                // Handle dropping a single item from all permissions to assigned permissions
                updatedAllPermissions = updatedAllPermissions?.filter(permission => permission !== draggedItem);
                updatedAssignedPermissions.push(draggedItem);
            }
        } else {
            // Handle dropping into all permissions
            if (selectedAssignedPermissions.length > 0) {
                // Handle dropping multiple selected items from assigned permissions to all permissions
                selectedAssignedPermissions.forEach(selectedItem => {
                    updatedAssignedPermissions = updatedAssignedPermissions?.filter(permission => permission !== selectedItem);
                    updatedAllPermissions.push(selectedItem);
                });
            } else {
                // Handle dropping a single item from assigned permissions to all permissions
                updatedAssignedPermissions = updatedAssignedPermissions?.filter(permission => permission !== draggedItem);
                updatedAllPermissions.push(draggedItem);
            }
        }

        setAllChart(updatedAllPermissions);
        setAssignedPermissions(updatedAssignedPermissions);
        setDraggedItem(null);
        setDraggedList(null);
        setSelectedAllPermissions([]);
        setSelectedAssignedPermissions([]);
    };
    const handleDragStart = (event, item, list) => {
        setDraggedItem(item);
        setDraggedList(list);
    };
    const handleItemClick = (event, item, list) => {
        if (event?.ctrlKey) {
            // If Ctrl key is pressed, toggle selection without clearing previous selections
            if (list === 'allPermissions') {
                setSelectedAllPermissions(prevSelectedItems =>
                    prevSelectedItems?.includes(item)
                        ? prevSelectedItems?.filter(selectedItem => selectedItem !== item)
                        : [...prevSelectedItems, item]
                );
            } else {
                setSelectedAssignedPermissions(prevSelectedItems =>
                    prevSelectedItems?.includes(item)
                        ? prevSelectedItems?.filter(selectedItem => selectedItem !== item)
                        : [...prevSelectedItems, item]
                );
            }
        } else {
            // If Ctrl key is not pressed, clear previous selections and select only the current item
            if (list === 'allPermissions') {
                setSelectedAllPermissions([item]);
                setSelectedAssignedPermissions([]);
            } else {
                setSelectedAssignedPermissions([item]);
                setSelectedAllPermissions([]);
            }
        }
    };
    const handleSubmit = () => {
        // Convert field names to lowercase and replace spaces with underscores
        const fields = assignedPermissions.map(field =>
            field?.toLowerCase()?.replace(/ /g, '_')
        );

        // Set the assigned chart with the new fields
        setAssignedChart(fields);

        // Update the perioChartForm
        setPerioChartForm(prevState => {
            // Map through each chart in prevState
            const updatedForm = prevState.map(chart => {
                const updatedBuccal = chart.buccal.map(tooth => {
                    // Create a new cal object based on the conditions
                    const newCal = {
                        area_1: fields.includes("probing_depth")
                            ? tooth.probing_depth?.area_1 || 0
                            : fields.includes("gingival_margin")
                                ? tooth.gingival_margin?.area_1 || 0
                                : 0,
                        area_2: fields.includes("probing_depth")
                            ? tooth.probing_depth?.area_2 || 0
                            : fields.includes("gingival_margin")
                                ? tooth.gingival_margin?.area_2 || 0
                                : 0,
                        area_3: fields.includes("probing_depth")
                            ? tooth.probing_depth?.area_3 || 0
                            : fields.includes("gingival_margin")
                                ? tooth.gingival_margin?.area_3 || 0
                                : 0
                    };

                    // If probing_depth is not included, set gingival_margin values to 0
                    if (!fields.includes("gingival_margin")) {
                        tooth.gingival_margin = {
                            area_1: 0,
                            area_2: 0,
                            area_3: 0
                        };
                    }

                    // If gingival_margin is not included, set probing_depth values to 0
                    if (!fields.includes("probing_depth")) {
                        tooth.probing_depth = {
                            area_1: 0,
                            area_2: 0,
                            area_3: 0
                        };
                    }

                    return {
                        ...tooth,
                        cal: newCal
                    };
                });

                const updatedLingual = chart.lingual.map(tooth => {
                    // Create a new cal object based on the conditions
                    const newCal = {
                        area_1: fields.includes("probing_depth")
                            ? tooth.probing_depth?.area_1 || 0
                            : fields.includes("gingival_margin")
                                ? tooth.gingival_margin?.area_1 || 0
                                : 0,
                        area_2: fields.includes("probing_depth")
                            ? tooth.probing_depth?.area_2 || 0
                            : fields.includes("gingival_margin")
                                ? tooth.gingival_margin?.area_2 || 0
                                : 0,
                        area_3: fields.includes("probing_depth")
                            ? tooth.probing_depth?.area_3 || 0
                            : fields.includes("gingival_margin")
                                ? tooth.gingival_margin?.area_3 || 0
                                : 0
                    };

                    // If probing_depth is not included, set gingival_margin values to 0
                    if (!fields.includes("gingival_margin")) {
                        tooth.gingival_margin = {
                            area_1: 0,
                            area_2: 0,
                            area_3: 0
                        };
                    }

                    // If gingival_margin is not included, set probing_depth values to 0
                    if (!fields.includes("probing_depth")) {
                        tooth.probing_depth = {
                            area_1: 0,
                            area_2: 0,
                            area_3: 0
                        };
                    }

                    return {
                        ...tooth,
                        cal: newCal
                    };
                });

                // Return the updated chart
                return {
                    ...chart,
                    buccal: updatedBuccal,
                    lingual: updatedLingual
                };
            });

            // Return the updated form
            return updatedForm;
        });

        // Set the chart setting modal
        setChartSettingModal(modalEnum);
    };
    const clearFunction = () => {
        setAssignedPermissions(chartsText)
        setChartSettingModal(modalEnum)
        setAllChart(chartNotAssigned)

    }
    const filterDataByAssignedChart = (data, assignedChart) => {
        return data?.map((tooth) => {
            const filteredBuccal = tooth.buccal?.map((entry) => {
                return assignedChart.reduce((acc, field) => {
                    if (entry[field] !== undefined) {
                        acc[field] = entry[field];
                    }
                    return acc;
                }, { tooth_no: entry.tooth_no.toString(), isShowTooth: entry?.isShowTooth });
            });

            const filteredLingual = tooth.lingual?.map((entry) => {
                return assignedChart.reduce((acc, field) => {
                    if (entry[field] !== undefined) {
                        acc[field] = entry[field];
                    }
                    return acc;
                }, { tooth_no: entry.tooth_no.toString(), isShowTooth: entry?.isShowTooth });
            });

            return {
                type: tooth.type,
                buccal: filteredBuccal,
                lingual: filteredLingual,
            };
        });
    };
    const setPerioChart = async (Manual) => {
        try {

            const filteredPerioChartData = filterDataByAssignedChart(perioChartForm, assignedChart);
            let payload = {
                perioChartData: filteredPerioChartData,
                clinicId: user?.clinic_id,
                patientId: PatientData?.id,
                mobility: values?.mobility,
                horizontalProbing: values?.horizontal_probing,
                gingivalMargin: values?.gingival_margin,
                probingDepth: values?.probing_depth,
                date: date,
                age: dentitionAge?.[0]?.value
            };
            // console.log(payload,'payload')
            // return
            if (Manual) {
                setSpinner(true);
            }
            let response = await PERIO_CHART(payload);
            toast.success(response.message);
            setSpinner(false);
            timeList();
        } catch (error) {
            setSpinner(false);
        }
    };
    const UpdatePerioChart = async () => {
        try {
            const filteredPerioChartData = filterDataByAssignedChart(perioChartForm, assignedChart);
            let payload = {
                perioChartData: filteredPerioChartData,
                clinicId: user?.clinic_id,
                patientId: PatientData?.id,
                mobility: values?.mobility,
                horizontalProbing: values?.horizontal_probing,
                gingivalMargin: values?.gingival_margin,
                probingDepth: values?.probing_depth,
                date: date,
                id: perioUpdate?.id,
                age: dentitionAge?.[0]?.value
            }
            setSpinner(true)
            let response = await PERIO_CHART_UPDATE(payload)
            toast.success(response.message)
            timeList()
            setSpinner(false)

        } catch (error) {
            setSpinner(false)

        }
    }
    const remove = async () => {
        try {
            let payload = {
                clinicId: user?.clinic_id,
                patientId: PatientData?.id,
            }
            setSpinner(true)
            let response = await REMOVE_TEETH(payload)
            setMissingTeeths(response?.data)
            setMissTeeth(response?.data)
            setSpinner(false)
        } catch (error) {
            setSpinner(false)
        }
    }
    const timeList = async (newAdd) => {
        try {
            let payload = {
                clinicId: user?.clinic_id,
                patientId: PatientData?.id
            }
            setSpinner(true)
            let response = await TIME_LIST(payload)
            setTimeListData(response.data)
            setSpinner(false)
            if (response.data.length > 0) {
                chartList(response.data[response?.data?.length - 1].createdDate, response.data[response?.data?.length - 1]?.id);

                setSelectedTime(response.data[response?.data?.length - 1].createdDate)
                setPerioUpdate((pre) => ({ ...pre, update: newAdd ? false : true }))
                if (user?.roleName !== "PROVIDER") {
                    setDisabled(true)
                }
            }
            else {
                setPerioUpdate((pre) => ({ ...pre, update: false }))
                if (user?.roleName !== "PROVIDER") {
                    setDisabled(true)
                }
            }
            setAssignedChart(Assigned_Chart_Names)


        } catch (error) {
            setSpinner(false)

        }
    }
    const chartList = async (date, id) => {
        try {
            let findId = id;
            if (!id) findId = timeListData?.find((item) => item?.createdDate === date)?.id
            setPerioUpdate((pre) => ({ ...pre, id: findId }))
            let payload = {
                clinicId: user?.clinic_id,
                patientId: PatientData?.id,
                createdDate: date ? date : '',
                id: findId
            }
            setSpinner(true)
            let response = await CHART_LIST(payload)
            setPerioChartForm(response?.data)
            setValues((pre) => ({
                ...pre, mobility: response?.mobility, gingival_margin: response?.gingivalMargin,
                horizontal_probing: response?.horizontalProbing, probing_depth: response?.probingDepth
            }))
            let findAge = dentitionAgeArray?.find((item) => item?.value === response?.age)
            setIsSettingDentitionAge(true);
            setDentitionAge([findAge])
            setSpinner(false)
            setPerioData(response?.data)
            setRoutingData((pre) => ({ ...pre, date: date, id: findId }))

            const allKeys = new Set();
            // Loop through each buccal and lingual array in the dental data
            response?.data.forEach((data) => {
                data.buccal.forEach((tooth) => {
                    Object.keys(tooth).forEach((key) => {
                        if (key !== 'tooth_no') {
                            allKeys.add(key);
                        }
                    });
                });
                data.lingual.forEach((tooth) => {
                    Object.keys(tooth).forEach((key) => {
                        if (key !== 'tooth_no') {
                            allKeys.add(key);
                        }
                    });
                });
            });
            const capitalizeWords = str => str?.replace(/_/g, ' ').replace(/\b\w/g, char => char?.toUpperCase());
            const originalFields = Array.from(allKeys)?.map(capitalizeWords);
            setAssignedPermissions(originalFields)

            setAssignedChart(Array.from(allKeys))
            const filterMissingFields = (data, originalFields) => {
                return data?.filter(field => !originalFields?.includes(field));
            };
            let missingFields = filterMissingFields(chart_Names, Array.from(allKeys));
            const originalAllChart = missingFields?.map(capitalizeWords);
            setAllChart(originalAllChart)
            // // setIsManualUpdate(false)
        } catch (error) {
            setSpinner(false)
        }
    }
    const handlePrint = useReactToPrint({
        content: () => inputRefs.current,
    });
    const newChartAdd = () => {
        setPerioChartForm([
            {
                type: "Upper",
                buccal: createToothArray(false),
                lingual: createToothArray(false)
            },
            {
                type: "Lower",
                lingual: createToothArray(true),
                buccal: createToothArray(true)
            },
        ])
        setDisabled(false);
        setChartAddModal(modalEnum)
        setPerioUpdate((pre) => ({ ...pre, update: false }))
        updateToothNumbers(dentitionAge?.[0]?.value, true)
    }
    const previousCopyChart = () => {
        timeList(true)
        setDisabled(false);
        setChartAddModal(modalEnum)
        setPerioUpdate((pre) => ({ ...pre, update: false }))
    }
    const autoSaveToggle = async (status) => {
        try {
            let payload = { status: status }
            setSpinner(true)
            let response = await AUTO_SAVE_TOGGLE(payload)
            toast.success(response?.message)
            setSpinner(false)
            getAutoSave()
        } catch (error) {
            setSpinner(false)

        }
    }
    const getAutoSave = async () => {
        try {
            setSpinner(true)
            let response = await GET_AUTO_SAVE_STATUS({})
            setAutoSave(response?.data?.perioChartAutoSave)
            setSpinner(false)
        } catch (error) {
            setSpinner(false)

        }
    }
    const handleDecrement = (label) => {
        setValues((prev) => {
            const updatedValues = {
                ...prev,
                [label]: Math.max(prev[label] - 1, 0), // Prevent going below 0
            };
            setPerioAutoSaveData({ ...perioChartForm, values: updatedValues }); // Ensure new reference
            return updatedValues;
        });
        setIsManualUpdate(false)
    };
    const handleIncrement = (label) => {
        setValues((prev) => {
            const updatedValues = {
                ...prev,
                [label]: prev[label] + 1,
            };
            setPerioAutoSaveData({ ...perioChartForm, values: updatedValues }); // Ensure new reference
            return updatedValues;
        });
        setIsManualUpdate(false)
    };
    const handleManualAdd = () => {
        setIsManualUpdate(true);
        setPerioChart(true);
    };
    const handleManualUpdate = () => {
        setIsManualUpdate(true);
        UpdatePerioChart();
    };
    const handleSelectChange = (e, changes) => {
        if (isSettingDentitionAge) {
            setIsSettingDentitionAge(false);
            return;
        }
        const selectedValue = e?.[0]?.value;
        setDentitionAge(e)
        if (changes) {
            updateToothNumbers(selectedValue, true)
        }
    };
    const updateToothNumbers = (value, Add) => {
        // Determine the tooth numbers based on the value
        const newUpperToothNumbers = (value === 6 || value === 7)
            ? Array.from({ length: 16 }, (_, i) => String(i + 1))
            : (value === 5) ? teethForValue5
                : teeths;

        const newLowerToothNumbers = (value === 6 || value === 7)
            ? Array.from({ length: 16 }, (_, i) => String(32 - i))
            : (value === 5) ? teethReverseForValue5
                : teethsReverse;
        // Determine if a tooth should be shown based on `value` and `toothNo`
        const shouldShowTooth = (toothNo) => {
            return value === 6 || value === 7 ? true :
                value === 1 ? false :
                    value === 5 && teethToHideForValue5.includes(toothNo) ? true :
                        value === 2 && teethToHideForValue2.includes(toothNo) ? true :
                            value === 3 && teethToHideForValue3.includes(toothNo) ? true :
                                value === 4 && teethToHideForValue4.includes(toothNo) ? true :
                                    false;
        };

        if (Add) {
            setPerioChartForm([
                {
                    type: "Upper",
                    buccal: createToothArray(false).map((tooth, index) => {
                        const toothNo = newUpperToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    }),
                    lingual: createToothArray(false).map((tooth, index) => {
                        const toothNo = newUpperToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    })
                },
                {
                    type: "Lower",
                    lingual: createToothArray(true).map((tooth, index) => {
                        const toothNo = newLowerToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    }),
                    buccal: createToothArray(true).map((tooth, index) => {
                        const toothNo = newLowerToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    })
                },
            ]);
            return;
        }

        const updatedForm = perioChartForm.map(section => {
            if (section.type === "Upper") {
                return {
                    ...section,
                    buccal: section.buccal.map((tooth, index) => {
                        const toothNo = newUpperToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    }),
                    lingual: section.lingual.map((tooth, index) => {
                        const toothNo = newUpperToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    })
                };
            } else if (section.type === "Lower") {
                return {
                    ...section,
                    buccal: section.buccal.map((tooth, index) => {
                        const toothNo = newLowerToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    }),
                    lingual: section.lingual.map((tooth, index) => {
                        const toothNo = newLowerToothNumbers[index] || String(tooth.tooth_no);
                        return {
                            ...tooth,
                            tooth_no: toothNo,
                            isShowTooth: shouldShowTooth(toothNo)
                        };
                    })
                };
            }
            return section; // Return other sections unchanged
        });

        setPerioChartForm(updatedForm);
    };

    return {
        data: {
            perioChartForm,
            toothObj,
            selectInput,
            chartSettingModal,
            selectedAllPermissions,
            chartsText,
            selectedAssignedPermissions,
            assignedPermissions,
            allChart,
            assignedChart,
            chartPosition,
            focusedInput,
            spinner,
            timeListData,
            disabled,
            selectedTime,
            inputRefs,
            chartAddModal,
            date,
            perioUpdate,
            autoSave,
            values,
            missingTeeths,
            routingData,
            dentitionAge
        },
        methods: {
            setSelectInput,
            renderRows,
            handleButtonClick,
            setChartSettingModal,
            handleDragOver,
            handleDragStart,
            handleItemClick,
            handleDrop,
            clearFunction,
            handleSubmit,
            setChartPosition,
            handleNumberType,
            handleFocusChange,
            setDisabled,
            setPerioChart,
            chartList,
            setSelectedTime,
            handlePrint,
            setChartAddModal,
            newChartAdd,
            previousCopyChart,
            setDate,
            UpdatePerioChart,
            handleDecrement,
            handleIncrement,
            autoSaveToggle,
            setIsFirstRender,
            handleManualUpdate,
            handleManualAdd,
            setDentitionAge,
            handleSelectChange
        }
    }
}

export default UsePerioChart