
import * as React from 'react';
import { Outlet, useLocation, NavLink, } from 'react-router-dom'
import { Tabs, Tab, AppBar, Box } from '@mui/material';
function TemplateLibrary() {
    const location = useLocation()

    const allTabs = [
        {
            label: "Template List",
            value: `/template_library`,
        },
        {
            label: "Category",
            value: `/template_library/template_category`,
        },
        {
            label: "Drafts",
            value: `/template_library/drafts`,
        },
    ];


    return (
      <>
        <div className='text-center section_header'>Template Library</div>
        <div>
            <AppBar position="static" className='border-bottom ps-sm-5' sx={{ background: "#fff", boxShadow: 'none' }}>
                <Box>
                    <Tabs className='ms-2  mx-md-0' indicatorColor="transparent" value={location.pathname} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                        {allTabs.map((item, i) =>
                            <Tab key={i} className='taberSquare'
                                label={item.label}
                                value={item.value}
                                component={NavLink}
                                to={item.value}
                            />
                        )}
                    </Tabs>
                </Box>
            </AppBar>
            <Outlet />
        </div>
      </>

    )
}

export default TemplateLibrary