// import { useState } from 'react';
// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'
// const PhoneNumber = ({ Name, Value, HandleChange, disabledInput, width, countrySelectComponent, error }) => {
//     const [countryMaxLength, setCountryMaxLength] = useState(15);
//     const formatPhoneNumber = (value) => {
//         if (!value) return '';
//         const phoneNumber = value.replace(/[^\d]/g, '');
//         const countryCode = phoneNumber.slice(0, 3);
//         const number = phoneNumber.slice(3);
//         return `+${countryCode} ${number}`;
//     };
//     const handleCountryChange = (selectedCountry) => {
//         const countryName = selectedCountry;
//         if (countryName === 'IN') {
//             setCountryMaxLength((pre) => 15);
//         } else {
//             setCountryMaxLength((pre) => 20);
//         }
//     };
//     return (
//         <>
//             <PhoneInput
//                 disabled={disabledInput}
//                 className={`${width && "w-100"}`}
//                 // defaultCountry={(timeZone == "Asia/Calcutta" || timeZone === "Asia/Kolkata") ? 'IN' : undefined}
//                 // countrySelectComponent={(timeZone == "Asia/Calcutta" || timeZone === "Asia/Kolkata") ? "null" : undefined}
//                 countrySelectComponent={countrySelectComponent}
//                 defaultCountry={'US'}
//                 countryCallingCodeEditable={false}
//                 international
//                 withCountryCallingCode
//                 value={Value && `${Value.includes("+") ? "" : "+"}${Value}`}
//                 onChange={(e) => HandleChange({ target: { name: Name, value: e } })}
//                 format={formatPhoneNumber}
//                 maxLength={countryMaxLength}  // Set the dynamic maxLength
//                 onCountryChange={(country) => handleCountryChange(country)}
//             />
//             {error && <p className="font-11" style={{ color: 'red' }}>{error}</p>}
//         </>

//     );
// };
// export default PhoneNumber

import { useState } from 'react';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const PhoneNumber = ({ Name, Value, HandleChange, disabledInput, width, countrySelectComponent, error }) => {
    const [countryMaxLength, setCountryMaxLength] = useState(15);

    const separateCountryCodeAndNumber = (phoneNumber) => {
        if (!phoneNumber) return { countryCode: '', number: '' };

        try {
            const parsedNumber = parsePhoneNumber(phoneNumber);
            const countryCode = `+${parsedNumber.countryCallingCode}`;
            const number = parsedNumber.nationalNumber;
            return { countryCode, number };
        } catch {
            return { countryCode: '', number: phoneNumber };
        }
    };

    const handleCountryChange = (selectedCountry) => {
        setCountryMaxLength(selectedCountry === 'IN' ? 15 : 20);
    };

    return (
        <>
            <PhoneInput
                disabled={disabledInput}
                className={`${width && "w-100"}`}
                countrySelectComponent={countrySelectComponent}
                defaultCountry="US"
                countryCallingCodeEditable={false}
                international
                withCountryCallingCode
                value={Value && `${Value.includes("+") ? "" : "+"}${Value}`}
                onChange={(e) => {
                    const { countryCode, number } = separateCountryCodeAndNumber(e);
                    HandleChange({ target: { name: Name, value: e, countryCode, number } });
                }}
                maxLength={countryMaxLength}  // Set the dynamic maxLength
                onCountryChange={handleCountryChange}
            />
            {error && <p className="font-11" style={{ color: 'red' }}>{error}</p>}
        </>
    );
};

export default PhoneNumber;
