
import { axiosInstance, baseUrl, handleErrors } from "./auth";
import { environment } from '../env/environment'
// import { useQuery } from 'react-query'

export const userProfileURL = environment.baseUrl + "/dental/api/uploads/profile/";
export const userLicenceURL = environment.baseUrl + "/dental/api/uploads/license/";
export const userInsuranceCardURL = environment.baseUrl + "/dental/api/uploads/insuranceCard/";
export const userPatDocumentsURL = environment.baseUrl + "/dental/api/uploads/documents/";
export const userXrayURL = environment.baseUrl + "/dental/api/uploads/xray/";
export const procedureCategoryImgUrl = environment.baseUrl + "/dental/api/uploads/procedureCategoryImage/";
export const procedureCodeImgUrl = environment.baseUrl + "/dental/api/uploads/procedureCodeImage/";
export const chatURL = environment.baseUrl + "/dental/api/uploads/chat/";
export const progressNoteAttachmentURL = environment.baseUrl + "/dental/api/uploads/progressNotesAttachment/";
// upload Image
export const UPLOAD_FILE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/upload`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// /upload/delete
export const DELETE_UPLOAD_FILE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/upload/delete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  country List
export const COUNTRY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/country/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//  state List
export const STATE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/state/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//  city List
export const CITY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/city/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// insurance/insuranceCompany/list
export const INSURANCE_COMPANY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insurance/insuranceCompany/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// INSURANCE_ADD
export const INSURANCE_ADD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insurance/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// INSURANCE_EDIT
export const INSURANCE_EDIT = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/insurance/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// provider and Hygienist list
export const PROVIDER_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/provider/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// Switch Profile
export const SWITCH_PROFILE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/switchProfile`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

