import { axiosInstance, baseUrl, handleErrors } from "./auth";

// ------------------------------------ procedure code apis ------------------------------------
//  Procedure List
export const PROCEDURE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/procedCodes/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const FILTEREDPROCEDURE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/procedCodes/getProcedureCode`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Add procedure
export const ADD_PROCEDURE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/procedCodes/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update procedure 
export const UPDATE_PROCEDURE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/procedCodes/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    Procedure  details
export const PROCEDURE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/procedCodes/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   DELETE procedure  
export const PROCEDURE_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/procedCodes/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE PROCEDURE CODE 
export const DELETE_PROCEDURE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/procedCodes/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// --------------------------------- procedure category list ----------------------------------
//  Procedure category List
export const PROCEDURE_CATEGORY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/procedCategory/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  Procedure category List
export const Procedure_Code_List = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/procedCodes/procedureCodelist`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}



//   Add procedure
export const ADD_PROCEDURE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/procedCategory/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update procedure 
export const UPDATE_PROCEDURE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/procedCategory/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    Procedure  details
export const PROCEDURE_CATEGORY_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/procedCategory/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Update procedure status 
export const PROCEDURE_CATEGORY_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/procedCategory/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE PROCEDURE CATEGORY 
export const DELETE_PROCEDURE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/procedCategory/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// ------------------------------------ MEDICAL HISTORY apis ------------------------------------
//  ---------------MEDICAL CATEGORY List --------------------
export const MEDICAL_CATEGORY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medicalHisformCategory/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Add MEDICAL CATEGORY
export const ADD_MEDICAL_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medicalHisformCategory`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update MEDICAL CATEGORY 
export const UPDATE_MEDICAL_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medicalHisformCategory`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    MEDICAL CATEGORY  details
export const MEDICAL_CATEGRORY_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/medicalHisformCategory/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   STATUS CHANGE MEDICAL CATEGRORY  
export const MEDCATEGORY_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medicalHisformCategory/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE MEDICAL CATEGORY
export const DELETE_MEDICAL_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medicalHisformCategory/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// ------------------------- MEDICAL HISTORY ------------------------------
//  ---------------MEDICAL List --------------------
export const MEDICAL_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medicalHistory/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//    -------------------------- MEDICAL ADD ------------------
export const ADD_MEDICAL = async (params) => {
    try {
        let response = await axiosInstance.post(`${baseUrl}/medicalHistory`, params)
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//    -------------------------- MEDICAL UPDATE ------------------
export const UPDATE_MEDICAL = async (params) => {
    try {
        let response = await axiosInstance.put(`${baseUrl}/medicalHistory`, params);
        return response.data
    } catch (error) {
        handleErrors(error)
    }
}

// -------------------- MEDICAl DETAILS -------------------------
export const MEDICAL_DETAILS = async (params) => {
    try {
        let response = await axiosInstance.get(`${baseUrl}/medicalHistory/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)

    }
}
//   STATUS CHANGE MEDICAL   
export const MEDICAL_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medicalHistory/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE MEDICAL 
export const DELETE_MEDICAL = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medicalHistory/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// --------------------------------- medication apis list ----------------------------------

// ---------------generic apis -----------------------
//  medication generic List
export const MEDICATION_GENERIC_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medication/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   Add medication generic
export const ADD_MEDICATION_GENERIC = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medication/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   Add medication generic copy
export const COPY_MEDICATION_GENERIC = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medication/addViaCopy`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//   update medication generic 
export const UPDATE_MEDICATION_GENERIC = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medication/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    medication generic  details
export const MEDICATION_GENERIC_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/medication/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Update medication generic status 
export const MEDICATION_GENERIC_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medication/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE medication generic  
export const DELETE_MEDICATION_GENERIC = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medication/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   fee Schedule  import
export const IMPORT_MEDICATION_GENERIC = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medication/createMedicationExcel`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const EXPORT_MEDICATION_GENERIC = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medication/listExport`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// --------------------------------- brand apis ------------------------------------

//  medication brand List
export const MEDICATION_BRAND_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medication/brand/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   Add medication brand
export const ADD_MEDICATION_BRAND = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/medication/brand`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update medication brand 
export const UPDATE_MEDICATION_BRAND = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medication/brand`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    medication brand  details
export const MEDICATION_BRAND_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/medication/brand/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Update medication brand status 
export const MEDICATION_BRAND_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medication/brand/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE medication brand  
export const DELETE_MEDICATION_BRAND = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/medication/brand/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


// ------------------------------------ DISCOUNT PLAN apis ------------------------------------
//  ---------------DISCOUNT_PLAN List --------------------
export const DISCOUNT_PLAN_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/discountPlan/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Add DISCOUNT_PLAN
export const ADD_DISCOUNT_PLAN = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/discountPlan/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update DISCOUNT_PLAN
export const UPDATE_DISCOUNT_PLAN = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/discountPlan/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    DISCOUNT_PLAN  details
export const DISCOUNT_PLAN_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/discountPlan/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   STATUS CHANGE DISCOUNT_PLAN
export const DISCOUNT_PLAN_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/discountPlan/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE DISCOUNT_PLAN
export const DELETE_DISCOUNT_PLAN = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/discountPlan/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}



// ------------------------------------ PHARMACIES apis ------------------------------------
//  ---------------PHARMACIES List --------------------
export const PHARMACIES_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/pharmacies/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Add PHARMACIES
export const ADD_PHARMACIES = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/pharmacies/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update PHARMACIES
export const UPDATE_PHARMACIES = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/pharmacies/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    PHARMACIES  details
export const PHARMACIES_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/pharmacies/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   STATUS CHANGE PHARMACIES
export const PHARMACIES_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/pharmacies/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE PHARMACIES
export const DELETE_PHARMACIES = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/pharmacies/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//------------------------------------------------ Note type --------------------------------------

//   NOTES TYPE LIST
export const NOTES_TYPE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/notesType/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}



//  ADD NOTES TYPE 
export const ADD_NOTES_TYPE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/notesType`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT NOTES TYPE 
export const EDIT_NOTES_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/notesType`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   NOTES TYPE  DETAILS
export const NOTES_TYPE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/notesType/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   NOTES TYPE  DELETE
export const NOTES_TYPE_DELETE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/notesType/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}



//---------------------------------------------------- Employers-----------------------------------
//   Employers list
export const EMPLOYERS_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/Employers/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD EMPLOYERS
export const ADD_EMPLOYERS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/Employers`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT EMPLOYERS
export const EDIT_EMPLOYERS = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/Employers`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   EMPLOYERS  DETAILS
export const EMPLOYERS_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/Employers/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   NOTES TYPE  DELETE
export const EMPLOYERS_DELETE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/Employers/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   NOTES TYPE  DELETE
export const ARCHIVE_EMPLOYERS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/Employers/toggleArchive`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//---------------------------------------------------- insurance company-----------------------------------
//   insurance Company list

export const INSURANCE_COMPANY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insuranceCompany/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  add insurance Company
export const ADD_INSURANCE_COMPANY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insuranceCompany/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  edit insurance Company
export const EDIT_INSURANCE_COMPANY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/insuranceCompany/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   insurance Company  DETAILS
export const INSURANCE_COMPANY_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/insuranceCompany/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   insurance Company  DELETE
export const ARCHIVE_INSURANCE_COMPANY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/insuranceCompany/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   insurance category list

export const INSURANCE_CATEGORY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insuranceCategory/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  add insurance category
export const ADD_INSURANCE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insuranceCategory`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  edit insurance category
export const EDIT_INSURANCE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/insuranceCategory`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   insurance category  DETAILS
export const INSURANCE_CATEGORY_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/insuranceCategory/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   insurance category  archive
export const ARCHIVE_INSURANCE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/insuranceCategory/toggleArchive`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//------------------------------------------------ Adjustment Type --------------------------------------

//   ADJUSTMENT list
export const ADJUSTMENT_TYPE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/adjustmentType/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD ADJUSTMENT TYPE
export const ADD_ADJUSTMENT_TYPE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/adjustmentType`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT  ADJUSTMENT TYPE
export const EDIT_ADJUSTMENT_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/adjustmentType`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   ADJUSTMENT TYPE  DETAILS
export const ADJUSTMENT_TYPE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/adjustmentType/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   ADJUSTMENT TYPE  DELETE
export const ADJUSTMENT_TYPE_DELETE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/adjustmentType/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//------------------------------------------------ Billing Type -------------------------------------- 
//   BILLING LIST
export const BILLING_TYPE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/billingType/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD BILLING TYPE
export const ADD_BILLING_TYPE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/billingType`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT  BILLING TYPE
export const EDIT_BILLING_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/billingType`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   fee Schedule TYPE  DETAILS
export const BILLING_TYPE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/billingType/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   BILLING TYPE  ARCHIVE
export const ARCHIVE_BILLING_TYPE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/billingType/toggleArchive`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//------------------------------------------------ fee Schedule -------------------------------------- 
//   fee Schedule LIST  
export const FEE_SCHEDULE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/feeSchedule/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD fee Schedule
export const ADD_FEE_SCHEDULE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/feeSchedule/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT  fee Schedule
export const EDIT_FEE_SCHEDULE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/feeSchedule/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   fee Schedule  DETAILS
export const FEE_SCHEDULE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/feeSchedule/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   fee Schedule  ARCHIVE
export const ARCHIVE_FEE_SCHEDULE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/feeSchedule/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   fee Schedule  DELETE
export const DELETE_FEE_SCHEDULE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/feeSchedule/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   fee Schedule  import
export const IMPORT_FEE_SCHEDULE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/feeSchedule/createFeeSchedularExcel`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
export const EXPORT_FEE_SCHEDULE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/feeSchedule/listExport`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//  TEMPLATE CATEGORY List 
export const TEMPLATEL_CATEGORY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/formCategory/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Add TEMPLATE CATEGORY
export const ADD_TEMPLATE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/formCategory`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update TEMPLATE CATEGORY 
export const UPDATE_TEMPLATE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/formCategory`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//    TEMPLATE CATEGORY  details
export const TEMPLATE_CATEGRORY_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/formCategory/getDetails/${params}`,);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   STATUS CHANGE TEMPLATE CATEGORY   
export const TEMPLATE_CATEGRORY_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/formCategory/toggleArchive`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE TEMPLATE CATEGORY
export const DELETE_TEMPLATE_CATEGORY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/formCategory/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//------------------------------------  Template Library-----------------------------

//  TEMPLATE LIBRARY List 
export const TEMPLATE_LIBRARY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/templateLibrary/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//     TEMPLATE LIBRARY ADD 
export const ADD_TEMPLATE_LIBRARY = async (params) => {
    try {
        let response = await axiosInstance.post(`${baseUrl}/templateLibrary`, params)
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//    TEMPLATE LIBRARY UPDATE 
export const UPDATE_TEMPLATE_LIBRARY = async (params) => {
    try {
        let response = await axiosInstance.put(`${baseUrl}/templateLibrary`, params);
        return response.data
    } catch (error) {
        handleErrors(error)
    }
}

// TEMPLATE LIBRARY DETAILS 
export const TEMPLATE_LIBRARY_DETAILS = async (params) => {
    try {
        let response = await axiosInstance.get(`${baseUrl}/templateLibrary/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)

    }
}
//   STATUS CHANGE TEMPLATE LIBRARY   
export const TEMPLATE_LIBRARY_STATUS_CHANGE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/templateLibrary/toggleArchive`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE TEMPLATE LIBRARY 
export const DELETE_TEMPLATE_LIBRARY = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/templateLibrary/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//---------------------------------------------------- Diagnosis Code-----------------------------------
//   Diagnosis Code list 
export const DIAGNOSIS_CODE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/diagnosticCodes/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD Diagnosis Code
export const ADD_DIAGNOSIS_CODE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/diagnosticCodes/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT Diagnosis Code
export const EDIT_DIAGNOSIS_CODE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/diagnosticCodes/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Diagnosis Code  DETAILS
export const DIAGNOSIS_CODE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/diagnosticCodes/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Diagnosis Code DELETE
export const DIAGNOSIS_CODE_DELETE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/diagnosticCodes/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Diagnosis Code  DELETE
export const ARCHIVE_DIAGNOSIS_CODE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/diagnosticCodes/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//---------------------------------------------------- Appointment Type-----------------------------------
//   appointment type list  
export const APPOINTMENT_TYPE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/apptType/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD appointment type
export const ADD_APPOINTMENT_TYPE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/apptType/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT appointment type
export const EDIT_APPOINTMENT_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/apptType/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   appointment type  DETAILS
export const APPOINTMENT_TYPE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/apptType/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   appointment type DELETE
export const APPOINTMENT_TYPE_DELETE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/apptType/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   appointment type  DELETE
export const CHANGE_STATUS_APPOINTMENT_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/apptType/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//---------------------------------------------------- Recall Type-----------------------------------
//   Recall Type list   
export const RECALL_TYPE_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/recallType/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD Recall Type
export const ADD_RECALL_TYPE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/recallType/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT Recall Type
export const EDIT_RECALL_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/recallType/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Recall Type  DETAILS
export const RECALL_TYPE_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/recallType/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Recall Type DELETE
export const RECALL_TYPE_DELETE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/recallType/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Recall Type  DELETE
export const CHANGE_STATUS_RECALL_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/recallType/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// RecallType settingAdd
export const ADD_RECALL_SETTING = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/recallType/settingAdd`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const RECALL_SETTING_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/recallType/getSettingDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const UPDATE_RECALL_SETTING = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/recallType/updateSetting`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}



//---------------------------------------------------- Reminders Type-----------------------------------
//  Reminders list    
export const REMINDER_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/apptReminder/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADDReminders
export const ADD_REMINDER = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/apptReminder`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDITReminders
export const EDIT_REMINDER = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/apptReminder`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  Reminders  DETAILS
export const REMINDER_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/apptReminder/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  Reminders DELETE
export const REMINDER_DELETE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/apptReminder/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  Reminders  DELETE
export const CHANGE_STATUS_REMINDER = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/apptReminder/toggleArchive`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//---------------------------------------------------- Scheduler Blockout-----------------------------------
//   Blockout list    
export const BLOCKOUT_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/blockOut/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD Blockout
export const ADD_BLOCKOUT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/blockOut/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT Blockout
export const EDIT_BLOCKOUT = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/blockOut/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Blockout  DETAILS
export const BLOCKOUT_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/blockOut/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Blockout DELETE
export const BLOCKOUT_DELETE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/blockOut/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Blockout  DELETE
export const CHANGE_STATUS_BLOCKOUT = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/blockOut/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//---------------------------------------------------- Scheduler Operatories-----------------------------------
//   Operatories list     
export const OPERATORIES_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/operatories/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD Operatories
export const ADD_OPERATORIES = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/operatories/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT Operatories
export const EDIT_OPERATORIES = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/operatories/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Operatories  DETAILS
export const OPERATORIES_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/operatories/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Operatories DELETE
export const OPERATORIES_DELETE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/operatories/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Operatories  DELETE
export const CHANGE_STATUS_OPERATORIES = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/operatories/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Operatories  combine
export const COMBINE_OPERATORIES = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/operatories/combine`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}





//---------------------------------------------------- Role permission-----------------------------------
//   Role permission list    
export const ROLE_PREMISSION_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/endUserRole/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD Role permission
export const ADD_ROLE_PREMISSION = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/endUserRole/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT Role permission
export const EDIT_ROLE_PREMISSION = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/endUserRole/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Role permission  DETAILS
export const ROLE_PREMISSION_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/endUserRole/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Role permission DELETE
export const ROLE_PREMISSION_DELETE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/endUserRole/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   Role permission  DELETE
export const CHANGE_STATUS_ROLE_PREMISSION = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/endUserRole/toggleStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//----------------------------------------------------Referral Slip-----------------------------------

//  Referral Slip list 
export const REFERRAL_SLIP_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/referralSlip/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  ADD Referral Slip
export const ADD_REFERRAL_SLIP = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/referralSlip/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT Referral Slip
export const EDIT_REFERRAL_SLIP = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/referralSlip/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  Referral Slip  DETAILS
export const REFERRAL_SLIP_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/referralSlip/getDetails/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//------------------------------------------------ policy --------------------------------------



//   POLICY LIST
export const POLICY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/polices/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  UPDATE POLICY 
export const UPDATE_POLICY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/polices`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//------------------------------------------------ security --------------------------------------

//   SECURITY LIST
export const SECURITY_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/security/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   SECURITY LIST
export const UPDATE_SECURITY = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/security`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// upadte tow factor 

export const UPDATE_TOW_FACTOR_AUTH = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/security/updateTwoFactorAuth`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};


// ADD_MACROS

export const ADD_MACROS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/macros/add`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

// MACROS_LIST
export const MACROS_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/macros/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// MACROS_LIST with filter category id
export const MACROS_LISTFORPROGRESSNOTES = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/macros/listForprocedures`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
//   UPDATE_MACROS 
export const UPDATE_MACROS = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/macros/update`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// Payment gateway Api's---------------------------------------------

//  ADD payment Gateway Credential
export const ADD_PAYMENT_GATEWAY_CREDENTIAL = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/paymentGatewayCredential`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//   payment Gateway Credential list
export const PAYMENT_GATEWAY_CREDENTIAL_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/paymentGatewayCredential/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  EDIT payment Gateway Credential
export const EDIT_PAYMENT_GATEWAY_CREDENTIAL = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/paymentGatewayCredential`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  payment Gateway Credential  DETAILS
export const PAYMENT_GATEWAY_CREDENTIAL_DETAILS = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/paymentGatewayCredential/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   Delete Payment Gateway Credential 
export const DELETE_PAYMENT_GATEWAY_CREDENTIAL = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/paymentGatewayCredential/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//  progress notes List
export const Progress_Notes_List = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/progressNotes/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// Add progress type
export const ADD_PROGRESSNOTE = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/progressNotes/addProgressNotes`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// GET_PROGRESSNOTE
export const GET_PROGRESSNOTE = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/progressNotes/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//  EDIT progress notes
export const EDIT_PROGRESSNOTE_TYPE = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/progressNotes`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
// ------------------------------------ conditions api ---------------------------------------
//  condition List
export const CONDITIOIN_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeCondition/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   Add condition 
export const ADD_CONDITION = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeCondition`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   update procedure 
export const UPDATE_CONDITION = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/restorativeCondition`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

//   DELETE condition 
export const DELETE_CONDITION = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeCondition/toggleDelete `, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  material List
export const MATERIAL_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeCondition/listMaterial`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

// ---------------------------------------------------- materials api -------------------------------------------

//   Add material 
export const ADD_MATERIAL = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeCondition/addmaterial`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


//  condition List
export const ALL_MATERIAL_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeCondition/listAllMaterial`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}
//  filtered material List
export const FILTERED_MATERIAL_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/restorativeCondition/listMaterial`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const CLINIC_SHIFT_List = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/shiftTime/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


export const ADD_CLINIC_SHIFT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/shiftTime`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const GET_CLINIC_SHIFT = async (params) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/shiftTime/${params}`);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const EDIT_CLINIC_SHIFT = async (params) => {
    try {
        const response = await axiosInstance.put(`${baseUrl}/shiftTime`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}


export const DELETE_CLINIC_SHIFT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/shiftTime/toggleDelete`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

