import React from 'react'
import './header.css';
import { NavLink, useNavigate } from 'react-router-dom';
import AddIcon from "../../Assets/Clinic_head_icons/addIcon.png"
import { IoEye } from "react-icons/io5";
import { ImFileText } from "react-icons/im";
import { IoMdEyeOff } from "react-icons/io";

const AppointmentHeader = ({ methods, data }) => {
    const navigate = useNavigate()
    const {
        hippModeOnOff
    } = data
    const {
        setCreateAppointmentModal,
        updatePrivacyMode

    } = methods

    return (
        <>
            <header id="header" className={`nestedHeader  d-flex align-items-center  `} style={{ background: "#0F6CBD" }}>
                <nav>
                    <ul className="nav-links">
                        <li onClick={() => setCreateAppointmentModal({ data: null, open: true, type: "CREATE" })}><img src={AddIcon} alt='' />Create Appointment</li>
                        <li className='d-flex align-items-center ' onClick={() => { updatePrivacyMode(!hippModeOnOff) }}> {hippModeOnOff ? <IoMdEyeOff className='font-18 me-1' /> : <IoEye className='font-18 me-1' />}  Privacy Mode</li>
                        <NavLink to={"/waiting_list"}><li><img src={AddIcon} alt='' /> Waiting List</li></NavLink>
                        <li className='d-flex align-items-center '><NavLink to="/appointment-requests" ><ImFileText className='font-18 me-1' />Appointment Requests</NavLink></li>
                    </ul>
                </nav>
            </header>
            {/* add appointment Modal */}


        </>
    )
}

export default AppointmentHeader