import React from 'react'
import { Select, MenuItem, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';
import FormField from '../../../../Components/common-components/form-input';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Spinner } from '../../../../Components/common-components/spinner';

const ViewTemplateLibrary = ({ methods, data }) => {
    const { spinner, viewFieldsArry, formCategoryList, templateName, formCategory } = data

    return (
        <div className="p-4 h-100">
            {spinner && <Spinner />}
            {/* <div className='pb-3 d-flex align-items-center' > <span onClick={() => navigate('/master-list/medical-history')} className='cursor'><span className='font-18 cursor'> <IoArrowBack /> </span> <span>View medical history</span> </span> </div> */}
            <div className='row p p-2 bg-body' style={{ height: '70vh', borderRadius: '10px', overflow: 'auto' }}>
                <div className=' col-12' >
                    <div className='d-flex flex-column justify-content-between border h-100 p-3' style={{ background: '#F5F5F5' }}>
                        <div className='row  pb-3'>
                            <div className='justify-content-between d-flex border-bottom pb-3 col-12'>
                                <div className='col-4'>
                                    <label className='d-block grey3 text-nowrap'>Form category</label>
                                    <Select
                                        readOnly
                                        value={formCategory}
                                        variant="standard"
                                        className='w-100'
                                        size='small'
                                        name='name' >
                                        {
                                            formCategoryList?.map((cur, index) => {
                                                return <MenuItem key={index} value={cur?.id}>{cur?.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className='col-4'>
                                    <label className='d-block text-nowrap col-6 grey3'>Form name</label>
                                    <TextField id="standard-search"
                                        value={templateName}
                                        type="text"
                                        variant="standard"
                                        className='w-100 '
                                        name='name'
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className='row page-content pt-2'>
                                {viewFieldsArry?.length > 0 && (
                                    viewFieldsArry?.map((result, index) => {
                                        if (result?.type === 'Text') {
                                            return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                                                <div >
                                                    <div className='d-flex justify-content-between mt-1'>
                                                        <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}  </div>
                                                    </div>
                                                </div>
                                                <FormField
                                                    name={'name'}
                                                    data={{ label: '', placeholder: '', type: 'text' }} />
                                            </div>
                                        }
                                        if (result?.type === 'Date') {
                                            return <>
                                                <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                                                    <div >
                                                        <div className='d-flex justify-content-between mt-1'>
                                                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}  </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3 col-12 border p-1 rounded bg-body">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                inputFormat="MM/dd/yyyy"
                                                                value={null}
                                                                // onChange={handleDateChange}
                                                                // onBlur={handleInputChange}
                                                                className='col-12 border-none'
                                                                name="dob"
                                                                renderInput={(params) => <TextField variant="standard" {...params}
                                                                />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        if (result?.type === 'Multiple Choice') {
                                            return <>
                                                <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                                                    <div >
                                                        <div className='d-flex justify-content-between mt-1'>
                                                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}
                                                                {
                                                                    <div>
                                                                        {result?.options?.length > 0 && (
                                                                            <FormControl>
                                                                                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                                                                                    {
                                                                                        result?.options?.map((val, index) =>
                                                                                            <FormControlLabel key={index} value={val} control={<Radio />} label={val?.name ? val?.name : val} />
                                                                                        )}
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        if (result?.type === 'CheckBox') {
                                            return <>
                                                <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                                                    <div >
                                                        <div className='d-flex justify-content-between mt-1'>
                                                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}
                                                                <FormGroup row>
                                                                    {
                                                                        <div>
                                                                            {result?.options?.length > 0 && (
                                                                                result?.options?.map((val) =>
                                                                                    <FormControlLabel key={index} value={val} control={<Checkbox />} label={val.name ? val.name : val} className='d-block' />
                                                                                ))}
                                                                        </div>
                                                                    }
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        if (result?.type === 'Dropdown') {
                                            return <>
                                                <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                                                    <div className='mt-1 Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}</div>
                                                    <div className=" col-12 mt-3 border rounded bg-body">
                                                        <Select
                                                            // value={result.options}
                                                            //   onChange={(e) => handleInputChange(e)}
                                                            variant="outlined"
                                                            className='w-100'
                                                            size='small'
                                                            name='dropdown'>
                                                            {result?.options?.length > 0 && (
                                                                result?.options?.map((val, index) => {
                                                                    return <MenuItem key={index} value={val}>{val.name ? val.name : val}</MenuItem>
                                                                }))
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        if (result?.type === 'Title') {
                                            return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                                                <div >
                                                    <div className='d-flex justify-content-between mt-1'>
                                                        <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}  </div>

                                                    </div>
                                                </div>
                                                <FormField
                                                    name={'name'}
                                                    data={{ label: '', placeholder: '', type: 'text' }} />
                                            </div>
                                        }
                                        return null
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewTemplateLibrary
