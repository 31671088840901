import { React } from 'react';
import FormField from "../../../../Components/common-components/form-input"
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import AddIcon from '@mui/icons-material/Add';
import TableHeadings from "../../../../Components/common-components/table-headings"
import { Switch } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "../../../../Components/PopUpModal";
import Pagination from "../../../../Components/Pagination"
import { Spinner } from "../../../../Components/common-components/spinner"
import UseTemplateLibrary from '../Hooks/useTemplate';
import CreateTemplateLibrary from './createTemplateLibrary';
import ViewTemplateLibrary from './viewTemplateLibrary';
import { BiCopyAlt } from "react-icons/bi";
import { getTableHead, Permission } from '../../../../Utils';
import { Permission_Message } from '../../../../Components/common-components/permissionMessage';
function TemplateLibraryList() {
    const { methods, data } = UseTemplateLibrary()
    // const navigate = useNavigate()
    // const id = useParams()
    const {
        spinner, totalCount, controller, deleteModal, filter, templateList, location, templateModal, viewTemplateModal, isCopyed } = data
    const {
        setDeleteModal,
        setTemplateModal,
        setViewTemplateModal,
        handleDelete,
        setController,
        status,
        setFilter,
        ApplyFilter,
        resetFilter,
        clearForm,
        getDetails,
        setIsCopyed,

    } = methods
    let TableHeadData = [
        { label: '#' },
        { label: 'Form Title' },
        { label: 'Category' },
        { label: 'Action' },
    ]
    let labelStatus = { label: 'Status' }
    if (location?.pathname === "/template_library") TableHeadData.splice(3, 0, labelStatus);

    const Add_Permission = Permission({ permission: 'Template Library Template-Able to add Template' })
    const Edit_Permission = Permission({ permission: 'Template Library Template-Able to edit Template' })
    const Copy_Permission = Permission({ permission: 'Template Library Template-Able to copy Template' })
    const View_Permission = Permission({ permission: 'Template Library Template-Able to View/List Template' })
    const Delete_Permission = Permission({ permission: 'Template Library Template-Able to Delete Template' })
    const Archive_UnArchive_Permission = Permission({ permission: 'Template Library Template-Able to Active/In-Active Template' })

    const Edit_Draft_Permission = Permission({ permission: 'Template Library Draft-Able to edit Draft' })
    const View_Draft_Permission = Permission({ permission: 'Template Library Draft-Able to View/List Draft' })
    const Delete_Draft_Permission = Permission({ permission: 'Template Library Draft-Able to Delete Draft' })
    const Draft_Archive_UnArchive_Permission = Permission({ permission: 'Template Library Draft-Able to Active/In-Active Draft' })


    const getTableHeadForTemplate = (Edit_Permission, Delete_Permission, Copy_Permission, TableHeadDataTableHeadData) => {
        if (!Edit_Permission && !Delete_Permission && !Copy_Permission) {
            return TableHeadDataTableHeadData.filter(item => item.label !== 'Action');
        }
        return TableHeadDataTableHeadData;
    };

    const isTemplateLibrary = location?.pathname === "/template_library/drafts";
    const tableHeadData = isTemplateLibrary ? getTableHead(Edit_Draft_Permission, Delete_Draft_Permission, TableHeadData) : getTableHeadForTemplate(Edit_Permission, Delete_Permission, Copy_Permission, TableHeadData);

    return (
        <div className='p-2'>
            {spinner && <Spinner />}
            <div className="d-lg-flex align-items-center">
                <div className='mx-2 mx-sm-0 pt-2'>
                    <FormField data={{ placeholder: 'Search', rightIcon: '', type: 'text' }} value={filter?.search} onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))} />
                </div>
                <div className="d-flex mt-3 ms-lg-3 ms-sm-0 ms-2">
                    <button className="theme-btn bgDarkBlue" onClick={ApplyFilter}> <SearchIcon style={{ height: "20px" }} /> Search</button>
                    <button className="theme-btn bgDarkBlue mx-3" onClick={resetFilter}> <RestartAltIcon style={{ height: "20px" }} /> Reset</button>
                    {(location.pathname !== "/template_library/drafts") && Add_Permission && <button className="theme-btn bgLightGreen" onClick={() => { clearForm(); setTemplateModal({ open: true, data: null }) }}> <AddIcon style={{ height: "20px" }} /> Add </button>}
                </div>
            </div>
            {
                (isTemplateLibrary ? View_Draft_Permission : View_Permission) ?
                    <div >
                        <div className="mx-2 mx-sm-0 mt-3 pb-3 overflowX">
                            <table className="customTable">
                                <TableHeadings TableHeadData={tableHeadData} />
                                <tbody>
                                    {templateList?.map((resp, index) =>
                                        <tr key={index}>
                                            <>
                                                <td> {controller.rowsPerPage * (controller.page - 1) + index + 1} </td>
                                                <td className='blue3 fw-6 cursor' onClick={(prev) => { getDetails(resp?.id); setViewTemplateModal({ ...prev, data: resp, open: true }) }}>{resp?.name}</td>
                                                <td className='blue3 fw-6 cursor' onClick={(prev) => { getDetails(resp?.id); setViewTemplateModal({ ...prev, data: resp, open: true }) }}>{resp?.formCategory || '--'}</td>
                                                {location.pathname !== "/template_library/drafts" && <td  >
                                                    <Switch checked={resp?.isActive} onChange={(e) => status(e, resp?.id)} title='Status Active/In-active' disabled={(isTemplateLibrary ? Draft_Archive_UnArchive_Permission : Archive_UnArchive_Permission) ? false : true} />
                                                </td>}
                                                {
                                                    (isTemplateLibrary ? (Delete_Draft_Permission || Edit_Draft_Permission) : (Delete_Permission || Edit_Permission || Copy_Permission)) &&
                                                    <td >
                                                        <div className='d-flex'>
                                                            {
                                                                location.pathname !== "/template_library/drafts" && Copy_Permission && <BiCopyAlt className='cursor me-1 font-23 blue1' style={{ marginTop: "3px" }} onClick={() => { setTemplateModal({ open: true, data: null }); getDetails(resp?.id); setIsCopyed(true) }} />
                                                            }

                                                            {
                                                                (isTemplateLibrary ? Edit_Draft_Permission : Edit_Permission) &&
                                                                <CreateIcon className='cursor' color="primary" titleAccess='Edit'
                                                                    onClick={() => { setTemplateModal((prev) => ({ ...prev, data: resp, open: true })); getDetails(resp?.id); clearForm(); }}
                                                                />
                                                            }
                                                            {
                                                                (isTemplateLibrary ? Delete_Draft_Permission : Delete_Permission) &&
                                                                <DeleteIcon className='cursor red' titleAccess='Delete'
                                                                    onClick={() => setDeleteModal((prev) => ({ ...prev, data: resp, open: true }))}
                                                                />

                                                            }
                                                        </div>
                                                    </td>
                                                }

                                            </>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {!templateList?.length && <div className="text-center mt-3">No record found</div>}
                            <div className="d-flex justify-content-center mt-2">
                                {
                                    totalCount > 10 && <Pagination count={totalCount} controller={controller} setController={setController} />
                                }
                            </div>
                        </div>
                    </div>
                    : Permission_Message
            }
            {/* delete Modal of template */}
            <Modal
                size="md" headerBorderBottom={true} show={deleteModal?.open} onHide={() => { setDeleteModal({ data: null, open: false }) }}
                bodyContent={
                    <div >
                        {/* <div className="row"> */}
                        <div className="text-center col-12">
                            <div className='mt-3 mb-4'>
                                <DeleteIcon className='cursor red' sx={{ fontSize: '50px' }} />
                            </div>
                            <div className="fw-7 font-22">Delete Temlate Library</div >
                            <div className="text-body-secondary font-13 my-2">
                                You are about to delete this template library <span className='black1 fw-7 font-12'>{deleteModal?.data?.name}</span> <br /> Are you sure?
                            </div>
                        </div>
                        {/* </div> */}

                        <div className="d-flex justify-content-center mt-3 mb-4">
                            <button type="button" className="theme-btn bg-danger col-5" onClick={handleDelete}> Delete</button>
                        </div>
                    </div>
                }
            />


            {/* add template Modal */}
            <Modal className="p-0" HeaderClass={'p-0'}
                size="xl" show={templateModal?.open} onHide={() => {
                    setTemplateModal({ data: null, open: false })
                }}
                headerContent={<> <div className='w-100 bgDarkBlue2 text-white p-2 text-center fw-5 font-14'>{isCopyed ? "Copy" : (templateModal?.data ? 'Update' : 'Create')} Template Form</div> </>}
                bodyContent={
                    <CreateTemplateLibrary data={data} methods={methods} />
                }
            />

            {/* view template Modal */}
            <Modal className="p-0" HeaderClass={'p-0'}
                size="xl" show={viewTemplateModal?.open} onHide={() => {
                    setViewTemplateModal({ data: null, open: false })
                    clearForm()
                    // setStateError({});
                }}
                //   headerContent={<h5 className="py-2 mb-0 fw-6 font-16">Create Template Form</h5>}
                headerContent={<> <div className='w-100 bgDarkBlue2 text-white p-2 text-center fw-5 font-14'>View Template Form</div> </>}
                bodyContent={
                    <ViewTemplateLibrary data={data} methods={methods} />
                }
            />
        </div>
    )
}

export default TemplateLibraryList