/* eslint-disable eqeqeq */
import React from 'react'
import { environment } from '../../env/environment';
import { useParams } from 'react-router-dom';
const SotaCloudIFrame = () => {
  const { type, examId, sotaCloudPatientId } = useParams()
  const baseUrl = environment?.SotaCloudBaseUrl


  const getIframeSrc = () => {
    switch (type) {
      case 'add':
        return `${baseUrl}?bridgeToSelectExam=1`;
      case 'view':
        return `${baseUrl}exam-view?examID=${examId}&patientID=${sotaCloudPatientId}`;
      case 'xray-view':
        return `${baseUrl}image-view/?imageID=${examId}&patientID=${sotaCloudPatientId}`;
      default:
        console.warn(`Unknown type: ${type}`);
        return `${baseUrl}/default`;
    }
  };

  return (<>
    {
      <div className='h-100 p-2'>
        <iframe
          src={getIframeSrc(type)}
          width="100%"
          height="100%"
          title="X-Ray Viewer"
        />
      </div>
    }

  </>
  )

}

export default SotaCloudIFrame





