import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import AddIcon from '@mui/icons-material/Add';
import FormField from '../../../Components/common-components/form-input';
import InputIcon from '@mui/icons-material/Input';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Switch, Avatar } from '@mui/material';
import TableHeadings from "../../../Components/common-components/table-headings";
import CreateIcon from '@mui/icons-material/Create';
import Pagination from "../../../Components/Pagination"
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "../../../Components/PopUpModal";
import UseCategory from './Hooks/useCategory';
import { Spinner } from '../../../Components/common-components/spinner';
import { Permission } from '../../../Utils';
import { Permission_Message } from '../../../Components/common-components/permissionMessage';
import { procedureCategoryImgUrl } from '../../../repository/common';
import { modalEnum } from '../../../constant';

const TableHeadDataTableHeadData = [
  { label: '#' },
  { label: 'Category Name' },
  { label: 'Hidden' },
  // { label: 'Action' },
]

const Category = () => {
  const { data, methods } = UseCategory()
  const {
    deleteModal,
    spinner,
    filter,
    categoryModal,
    categoryList,
    controller,
    totalCount,
    categoryInputs,
    categoryError,
    categoryImage,
    FileRef
  } = data
  const { setDeleteModal,
    setCategoryModal,
    setFilter,
    setCategoryInputs,
    handleInputChange,
    ApplyFilter,
    resetFilter,
    handleBlur,
    clearForm,
    handleAddSubmit,
    setCategoryImage,
    UploadImage,
    handleupdateSubmit,
    status,
    setController,
    setSpinner,
    handleDelete
  } = methods


  const Add_Permission = Permission({ permission: 'Setup-Procedure Code Category-Able to add Procedure Code Category' })
  const Edit_Permission = Permission({ permission: 'Setup-Procedure Code Category-Able to edit Procedure Code Category' })
  const View_Permission = Permission({ permission: 'Setup-Procedure Code Category-Able to View/List Procedure Code Category' })
  const Delete_Permission = Permission({ permission: 'Setup-Procedure Code Category-Able to Delete Procedure Code Category' })
  const Archive_UnArchive_Permission = Permission({ permission: 'Setup-Procedure Code Category-Able to Active/In-Active Procedure Code Category' })

  const getClinicLocationTableHeadData = (Edit_Permission, Delete_Permission) => {
    if (!Edit_Permission && !Delete_Permission) {
      return TableHeadDataTableHeadData.filter(item => item.label !== 'Action');
    }
    return TableHeadDataTableHeadData;
  };
  const tableHeadData = getClinicLocationTableHeadData(Edit_Permission, Delete_Permission);
  return (
    <>
      {spinner && <Spinner />}
      <div className='container-fluid'>
        <div className="d-sm-flex d-md-flex d-lg-flex align-items-center justify-content-between">
          <div className='d-flex'>
            <div className='mt-2'>
              <FormField data={{ placeholder: 'Search', rightIcon: '', type: 'text' }} value={filter?.search} onChange={(e) => setFilter((prev) => ({ ...prev, search: e.target.value }))} />
            </div>
            <div className="d-flex ms-2">
              <button className="theme-btn bgDarkBlue  mt-3 mx-lg-2 mx-md-2 mx-sm-2 me-2" onClick={ApplyFilter}> <SearchIcon style={{ height: "20px" }} /> Search</button>
              <button className="theme-btn bgDarkBlue  mt-3 mx-lg-2 mx-md-2 mx-sm-2 me-2" onClick={resetFilter}> <RestartAltIcon style={{ height: "20px" }} /> Reset</button>
              {/* {Add_Permission && <button className="theme-btn bgLightGreen mt-3 ms-2" onClick={() => setCategoryModal((pre) => ({ ...pre, open: true }))}> <AddIcon style={{ height: "20px" }} /> Add New</button>} */}
            </div>
          </div>
          {/* <div className="d-flex">
            <button className="theme-btn bgDarkBlue2 mt-3 me-2"> <SaveAltIcon style={{ height: "20px" }} />Import</button>
            <button className="theme-btn bgDarkBlue2 mt-3 ms-2"> <InputIcon style={{ height: "20px" }} />Export</button>
          </div> */}
        </div>
        {
          View_Permission ?
            <div>
              <div className="col-12 mt-3 pb-3" >
                <table className="customTable">
                  <TableHeadings TableHeadData={tableHeadData} />
                  <tbody>
                    {
                      categoryList?.map((resp, index) =>
                        <tr key={resp?.id}>
                          <>
                            <td >{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                            <td >{resp?.name}</td>
                            <td  >
                              <Switch checked={resp?.isActive} title='Status Active/In-active'
                                onChange={(e) => status(resp, e?.target?.checked)} disabled={Archive_UnArchive_Permission ? false : true}
                              />
                            </td>
                            {/* {
                              !Delete_Permission && !Edit_Permission ? null
                                :
                                <td>
                                  <div className='d-flex'>
                                    {
                                      Delete_Permission &&
                                      <DeleteIcon className='cursor red' titleAccess='Delete'
                                        onClick={() => setDeleteModal((prev) => ({ ...prev, data: resp, open: true }))}
                                      />
                                    }
                                    {
                                      Edit_Permission &&
                                      <CreateIcon className='cursor' color="primary" titleAccess='Edit'
                                        onClick={() => {
                                          setCategoryInputs({
                                            name: resp?.name,
                                          });
                                          setCategoryModal((prev) => ({ ...prev, data: { id: resp?.id, isActive: resp?.isActive }, open: true }))
                                        }}
                                      />
                                    }
                                  </div>
                                </td>
                            } */}

                          </>
                        </tr>
                      )}
                  </tbody>
                </table>

                {!categoryList?.length && <div className="text-center mt-2">No record found</div>}
                <div className="d-flex justify-content-center mt-2">
                  {
                    totalCount > 10 && <Pagination count={totalCount} controller={controller} setController={setController} />
                  }
                </div>
              </div>
            </div>
            : Permission_Message
        }

        {/* Form Modal add Procedure */}
        <Modal className="p-0"
          size="md" show={categoryModal?.open} onHide={() => { setCategoryModal(modalEnum) }}
          headerContent={<h5 className="py-2 mb-0 fw-6">{`${categoryModal?.data?.id ? 'Edit' : 'Add'}`} Category</h5>}
          bodyContent={
            <div >
              <div className="row p-4 pb-5">
                <div className="col-12 ">
                  <label className='d-block font-13 fw-6 pb-1'>Category Icon </label>
                  {!categoryImage ? <div className='bgGrey1 p-1 px-2 rounded-1'>
                    <input type="file" ref={FileRef} className='p-0 font-14' accept="image/*" name="procedureCategoryImage" onChange={(e) => UploadImage(e, FileRef, "procedureCategoryImage", setSpinner, setCategoryImage)} />
                  </div>
                    : <div className='d-flex '>
                      <Avatar alt="Travis Howard" sx={{ width: 40, height: 40 }}
                        src={procedureCategoryImgUrl + categoryImage}>
                      </Avatar>
                      <p className='text-danger border rounded px-1 cursor ms-1' onClick={() => setCategoryImage(null)}>x</p>
                    </div>}
                </div>

                <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                  <FormField
                    required={true}
                    maxLength={50}
                    name={'name'}
                    value={categoryInputs?.name}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleInputChange(e)}
                    error={categoryError?.name}
                    data={{ label: 'Category Name', placeholder: '', type: 'text' }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end border-top p-3">
                <div>
                  <button type="submit" className="theme-btn bgLightGray col-11 px-5" onClick={() => { clearForm(); setCategoryModal({ data: {}, open: false }) }}>Discard</button>
                </div>
                <div>
                  {categoryModal?.data?.id ? <button type="button" className="theme-btn bgDarkBlue col-11 px-5" onClick={handleupdateSubmit}> Update</button>
                    :
                    <button type="button" className="theme-btn bgDarkBlue col-11 ms-2 px-5" onClick={handleAddSubmit}>Save</button>
                  }
                </div>
              </div>

              {/* <div className="d-flex justify-content-end border-top p-4">
                            <div>
                                <button type="submit" className="theme-btn bgLightGray col-11 px-md-5" onClick={() => {
                                    clearForm()
                                    setCategoryModal({ data: "", open: false })
                                }}> Cancel</button>
                            </div>
                            <div>
                                {categoryModal?.data?.id ? <button type="button" className="theme-btn bgDarkBlue col-11 px-md-5" onClick={handleupdateSubmit}> Update</button>
                                    :
                                    <button type="button" className="theme-btn bgDarkBlue col-11 ms-2 px-md-5" onClick={handleAddSubmit}> Submit</button>
                                }
                            </div>
                        </div> */}
            </div>
          } />

        {/* Delete Modal of Procedure Code */}
        <Modal
          size="md" headerBorderBottom={true} show={deleteModal?.open} onHide={() => { setDeleteModal(modalEnum) }}
          bodyContent={
            <div >
              <div className="text-center col-12">
                <div className='mt-3 mb-4'>
                  <DeleteIcon className='cursor red' sx={{ fontSize: '50px' }} />
                </div>
                <div className="fw-6 font-22">Delete Category</div >
                <div className="grey-color font-15 my-2">
                  You are about to delete this Category <br /> Are you sure?
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 mb-4">
                <button type="button" className="theme-btn  col-5 font-12 fw-5 bgRed" onClick={handleDelete}> Delete</button>
              </div>
            </div>
          }
        />
      </div>
    </>
  )
}

export default Category