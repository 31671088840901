import { createSlice } from "@reduxjs/toolkit";

const patientSlice = createSlice({
    name: "patientData",
    initialState: {},
    reducers: {
        patientDetails(state, action) {
            return state = action.payload;
        }
    }
})
export const { patientDetails } = patientSlice.actions;
export default patientSlice.reducer;