import { environment } from '../env/environment'
import axios from 'axios'
import authHead from './auth-header'

export const baseUrl = `${environment.baseUrl}/dental/api/clicSec`
export const axiosInstance = axios.create({ baseUrl })

// handle Request and add globle token in header section
axiosInstance.interceptors.request.use(async (config) => {
    let token = authHead()
    if (token) {
        config.headers.Authorization = token.Authorization
    }
    return config
}, (error) => {
    return Promise.reject(error)
}
)

// Add a 401 response interceptor
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error) {
        if (error.response && error.response.status === 401) {
            setTimeout(() => {
                localStorage?.clear()
                sessionStorage?.clear()
                window.location = '/';
                localStorage.setItem("authError", JSON.stringify("Your login session has timed out. Please log in again to continue."))
            }, 1500)
            // return Promise.reject(error.response.data.errors);
            return Promise.reject(error);
        }
        if (error.response && error.response.status === 404 && error.code !== "ERR_NETWORK") {
            error.message = "Server is offline. Please try again later."
        }
        if (error?.code === "ERR_NETWORK") {
            error.message = "Server is offline. Please try again later."
        }
        return Promise.reject(error);
    }
});

export const handleErrors = (error) => {
    if (error?.code === "ERR_NETWORK") {
        throw new Error("Network error: Unable to reach the server")
    } else if (error?.response?.data?.message) {
        throw new Error(error.response.data.message);
    } else if (error.response.status === 401) {
        throw new Error("Token Expire");
    }
    else {
        throw new Error("An unspecified error occurred.");
    }
}

// chack first time user login
export const CHECK_USER_STATUS = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/checkUserStatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

// user login
export const USER_LOGIN = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/login`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// verify otp
export const VERIFY_FIRST_TIME_OTP = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/verifyFirstTimeOtp`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// send otp
export const SEND_OTP = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/sendOtp`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

//  first time set password
export const CREATE_PASSWORD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/setPassword`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

// upadte tow factor 

export const UPDATE_TOW_FACTOR_AUTH = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/updateTwoFactorAuth`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

// skip tow factor
export const SKIP_TOW_FACTOR_AUTH = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/skipTwoFactorAuth`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// verification tow factor
export const VERIFYCATION_TOW_FACTOR_AUTH = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/verifyLoginOtp`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// forgot password
export const FORGOT_PASSWORD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/forgotPassword`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// reset password
export const RESET_PASSWORD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/resetPassword`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// reset password team member
export const RESET_PASSWORD_TEAM_MEMBER = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/resetPasswordTeamMember`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};
// verify token when we create team member new password
export const VERIFY_TOKEN = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/verifyToken`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

// reset password
export const VERIFY_FORGOT_PASSWORD_OTP = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/verifyForgotPasswordOtp`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

// Change password
export const CHANGE_PASSWORD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/changePassword`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

export const CLINIC_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/listClinics`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

export const CREATE_PATIENT_PASSWORD = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/patient/setPassword`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

export const LOGOUT = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/user/logout`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};

// Complete Link Payment
export const CompleteLinkPayment = async (params) => {
    try {
        const response = await axiosInstance.post(`${environment?.baseUrl}/dental/api/user_subscription_plan/completeLinkPayment`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
};







