import React, { useContext, useEffect, useRef, useState } from 'react'
import Logo from '../Logo';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoLogOut } from "react-icons/io5";
import useReduxStore from '../../../hooks/useReduxStore';
import { patientDetails } from '../../../Slices/PatientSlice';
import { add, } from "../../../Slices/AuthSlice";

import home from '../../../Assets/icons/Vector.svg'
import Appointment from '../../../Assets/icons/Vector-5.svg'
import Forms from '../../../Assets/icons/Vector-1.svg'
import Financial from '../../../Assets/icons/Vector-10.svg'
import Chat from '../../../Assets/icons/Vector-6.svg'
import Ticket from '../../../Assets/icons/Vector-2.svg'
import Prescription from '../../../Assets/icons/Vector-7.svg'
import TreatmentPlans from '../../../Assets/icons/Vector-8.svg'
import settings from '../../../Assets/icons/Vector-9.svg'
import { Avatar, Badge, Tooltip } from '@mui/material';
import { userProfileURL } from '../../../repository/common';
import { truncateText } from '../../../Utils';
import { LOGOUT } from '../../../repository/auth';
import { NotificationsState } from '../../../App';
import useBroserNotification from '../../../hooks/useBroserNotification';
import { NOTIFICATION_LIST } from '../../../repository/NotificationRepo';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { toast } from 'react-toastify';

const PatientHeader = () => {
    const { Dispatch, selector: user } = useReduxStore("auth")
    const navigate = useNavigate()
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [notificationList, setNotificationList] = useState([])
    const notificationRef = useRef(null)
    const { notificationcount, socket, setNotificationcount } = useContext(NotificationsState)
    const [openNotification, setOpenNotification] = useState(false);
    const { showNotification } = useBroserNotification()
    const handleClickNotification = () => {
        setOpenNotification((pre) => !pre);
    };
    const handleClickViewAll = () => {
        setOpenNotification(false)
        navigate("notification")
    }
    // const logout = () => {
    //     Dispatch(add({}))
    //     Dispatch(patientDetails({}))
    //     navigate("/")
    // }

    const logout = async () => {
        try {
            let payload = {
                token: user?.token
            }
            await LOGOUT(payload)
            Dispatch(add({}))
            Dispatch(patientDetails({}))
            navigate("/")
        } catch (error) {
            toast.error(error?.message)
        }
    }

    let fullName = `${user?.first_name ? user?.first_name : "-"} ${user?.last_name ? user?.last_name : "-"}`

    const getNotificationList = async (newPage) => {
        let payload = {
            count: 10,
            page: newPage,
            clinicId: user?.clinic_id,
            userId: user?.user_id,
            filter: "UNREAD"
        }
        try {
            let response = await NOTIFICATION_LIST(payload)
            setNotificationList((prevList) =>
                newPage === 1 ? response.data : [...prevList, ...response.data]
            );
            let totalPage = Math.ceil(response.totalCount / 10)
            setTotalPages(totalPage)
            setNotificationcount(response?.unreadCount)
        } catch (error) {
            setNotificationList([])
            setNotificationcount(0)
        }
    }
    useEffect(() => {
        if (socket && socket?.readyState === WebSocket?.OPEN) {
            const handleMessage = (event) => {
                const socketData = JSON.parse(event.data);
                if (socketData?.data?.eventType === 'newNotification') {
                    getNotificationList(1);
                    let notificationData = {
                        message: socketData?.data?.message,
                        type: socketData?.data?.type,
                        title: socketData?.data?.title
                    };
                    const setNotificationData = (routes) => {
                        notificationData.routes = routes;
                    };
                    switch (socketData?.data?.type) {
                        case "BOOKAPPOINTMENT": case "CANCELAPPOINTMENT": case "RESCHEDULEAPPOINTMENT": case "REQUESTAPPOINTMENT":
                            setNotificationData(`/appointment-list`);
                            break;
                        default:
                            break;
                    }
                    showNotification(notificationData);
                }
                if (socketData?.eventType === 'receivedMessage') {
                    let notificationData = {
                        message: socketData?.data?.message,
                        type: socketData?.data?.type,
                        title: socketData?.data?.title,
                        routes: "/inbox"
                    };
                    showNotification(notificationData);
                }
            };
            socket.addEventListener('message', handleMessage);

            return () => {
                socket.removeEventListener('message', handleMessage);
            };
        }

    }, [socket]);

    const handleScroll = (e) => {
        const target = e.target;
        if (target?.scrollHeight - target?.scrollTop === target?.clientHeight) {
            if (selectedPage !== totalPages) {
                setSelectedPage((prevPage) => {
                    const newPage = prevPage + 1;
                    getNotificationList(newPage);
                    return newPage;
                });
            }
        }
    };

    useEffect(() => {
        if (notificationList.length === 0 && selectedPage === 1) {
            getNotificationList(1)
        }
    }, [])

    useEffect(() => {
        if (openNotification) {
            getNotificationList(1)
        }
    }, [openNotification])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setOpenNotification(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            {/* {spinner && <Spinner />} */}
            <header id="header" className='logo-header d-flex align-items-center'>
                <Logo />
            </header>
            <header id="header" className='header  d-flex align-items-center justify-content-between'>
                <nav>
                    <ul className="nav-links">
                        <NavLink to={'/dashboard'}><li><img src={home} height={30} alt='img' />Dashboard</li></NavLink>
                        <NavLink to={'/appointment-list'}><li><img src={Appointment} alt='img' />  Appointments </li></NavLink>
                        <NavLink to={"/financial-management"}> <li><img src={Financial} alt='img' />Financial Management</li></NavLink>
                        <NavLink to={"/form"}><li><img src={Forms} alt='img' height={30} />Forms </li></NavLink>
                        <NavLink to={"/consent-form"}><li><img src={Forms} alt='img' height={30} /> Consent Forms </li></NavLink>
                        <NavLink to={"/treatment-plan"}><li><img src={TreatmentPlans} alt='img' />Treatment Plans</li></NavLink>
                        <NavLink to={"/prescription"}><li><img src={Prescription} alt='img' />Prescription </li></NavLink>
                        <NavLink to={"/inbox"}><li><img src={Chat} alt='img' height={28} />  Chat </li></NavLink>
                        <NavLink to={"/ticket-easy"}><li><img src={Ticket} alt='img' height={28} />Ticket Easy</li></NavLink>
                        <NavLink to={"/setting"}><li><img src={settings} alt='img' height={28} />Setting</li></NavLink>
                    </ul>
                </nav>
                <div className='d-flex justify-content-center align-items-center '>
                    {/* <p className='white me-3  d-flex'>
                        <div className='d-flex justify-content-center me-1 cursor mt-1' style={{ background: '#ccc', borderRadius: '50%', border: '1px solid white', width: '20px', height: '20px' }}>
                            <img src={userLocation} alt='location' />
                        </div>
                        <span className='font-13 mt-1 cursor' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {"Location"}
                        </span>
                        <ArrowDropDownIcon className='cursor' aria-labelledby="dropdownMenuLink" />
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            {ClinicLocationListListData?.length > 0 ? (
                                ClinicLocationListListData?.map((item) => (
                                    <li key={item?.id} className={user?.location_id == item?.id && "bgDarkBlue2 "} >
                                        <a className={` ${user?.location_id == item?.id && "grey4"} dropdown-item cursor`} onClick={() => { Dispatch(update({ location_id: item?.id })) }}>
                                            {item?.name}
                                        </a>
                                    </li>
                                ))
                            ) : (
                                <li><a className="dropdown-item">No locations found</a></li>
                            )}
                        </ul>
                    </p> */}

                    <Badge
                        ref={notificationRef}
                        onClick={handleClickNotification}
                        className="cursor me-3 position-relative"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        color="error"
                        badgeContent={notificationcount}>

                        <IoMdNotificationsOutline size={30} style={{ color: "#fff" }} />
                        {
                            openNotification && <div className='position-absolute shadow NOTIFICATION_BOX  ' onClick={(e) => e.stopPropagation()} >
                                {/* <ul className=' pt-2 SCROLL_NOTIFICATION_LIST' onScroll={handleScroll} style={{ height: "30vh" }}> */}
                                <ul className=' pt-2 SCROLL_NOTIFICATION_LIST' onScroll={handleScroll}>
                                    {
                                        notificationList?.map((cur, index) => {
                                            return <li style={{
                                                padding: "10px 5px",
                                                borderBottom: "1px solid #ccc",
                                                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff"  // Alternating background colors
                                            }} key={cur?.notificationId}  >{cur?.message}</li>
                                        })
                                    }
                                    {notificationList?.length === 0 && <li className='text-center position-absolute' style={{ top: "40%" }}>No Notification</li>}
                                </ul>
                                <div className='MORE' >
                                    <p onClick={handleClickViewAll}>View All</p>
                                </div>

                            </div>
                        }
                    </Badge>
                    <Tooltip title="Profile" >
                        <div className='d-flex align-items-center' onClick={() => navigate("/profile")}>
                            <Avatar
                                className="cursor"
                                src={userProfileURL + user?.image}
                                sx={{ width: 40, height: 40 }}
                            />
                            <p className='mb-0 ms-2 cursor white font-13'> {truncateText(fullName, 10)} </p>
                        </div>
                    </Tooltip>
                    <p className='m-0 d-flex  cursor white font-13 ms-3' onClick={logout} > <IoLogOut className="font-23" />Logout</p>
                </div>
            </header>
        </>
    )
}

export default PatientHeader
