import React from 'react'
import { Button, Select, MenuItem, TextField, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Switch, } from '@mui/material';
import FormField from '../../../../Components/common-components/form-input';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TagsInput } from 'react-tag-input-component';
import { RiFileCopy2Fill } from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";
import { LiaEditSolid } from "react-icons/lia";
import { Spinner } from '../../../../Components/common-components/spinner';


const CreateTemplateLibrary = ({ methods, data }) => {
  const {
    spinner,
    viewFieldsArry,
    formCategoryList,
    templateName,
    formCategory,
    filterStatus,
    templateModal,
    tab,
    fields,
    fieldsArry,
    fieldSizeData,
  } = data
  const {
    // -------Form-------
    handleAddSubmit,
    handleupdateSubmit,
    copyField,
    setFormCategory,
    setTemplateName,
    setTemplateModal,
    setTab,
    handleInputChange,
    handleViewFields,
    handleChange,
    updatedymanicform,
    editFieldOption,
    deleteField
  } = methods
  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  // const navigate = useNavigate()

  const handleTabs = () => {
    if (tab === 'ADDFIELD') {
      return (<div className=' p-sm-4 px-4 py-2 row '>
        {
          fieldsArry?.map((res, index) =>
            <span className='col-6 ps-0' key={index}>
              <button className='w-100 theme-btn bgDarkBlue2 m-2 text-start fieldFont' onClick={() => { handleViewFields(res, index) }}> <span className='mx-md-2 grey4 fieldFont' >{res.icon}</span> {res?.label}</button>
            </span>
          )
        }
      </div>)
    }
    if (tab === 'FIELDOPTIONS') {
      // if (!viewFieldsArry?.length) return toast.warn('Please add minimum 1 field')
      return (
        <div className='p-lg-4 p-md-4 pd-sm-4 p-2 row '>
          <div className='col-12 mb-4'>
            <label className='d-block mb-1 fw-6 font-15' >Label</label>
            <TextField id="standard-search"
              onChange={(e) => handleInputChange(e)}
              type="search"
              variant="outlined"
              size='small'
              className='w-100 '
              name='label'
              value={fields?.label} />
          </div>
          <div className='col-12 '>
            <label className='d-block mb-1 fw-6 font-15' >Field Size</label>
            <Select
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              className='w-100'
              size='small'
              name='fieldSize'
              value={fields?.fieldSize}

            >
              {
                fieldSizeData?.map((cur, index) => {
                  return <MenuItem key={index} value={cur?.value}>{cur?.title}</MenuItem>
                })
              }
            </Select>
          </div>
          <div className='col-12'>
            {
              ['CheckBox', 'Dropdown', 'Multiple Choice']?.includes(fields?.type) && <div className="Word-Break mt-4">
                <TagsInput
                  autocomplete
                  delimiters={delimiters}
                  value={fields?.options}
                  onChange={(e) => handleChange(e, "options")}
                  name="options"
                  placeHolder="Enter option"
                  classNames={"Word-Break"}
                />
              </div>
            }
          </div>
          <div className="col-12 d-flex align-items-center justify-content-between mt-2">
            Required <Switch checked={fields?.required} title='Active/In-active'
              onChange={(e) => handleInputChange({ target: { name: "required", value: e?.target?.checked } })} />
          </div>
          <div className=' mt-3 d-flex justify-content-center'>
            <Button variant="contained"
              onClick={() => { updatedymanicform() }}
            >Update </Button>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {spinner && <Spinner />}
      <div className='row p-lg-4 p-md-4 p-2 overflow_Scroll' style={{ height: '80vh' }}>
        <div className='col-lg-4 col-md-5 col-12' >
          <div className='border h-100' style={{ background: '#F5F5F5' }}>
            <div className='col-12 d-flex'>
              <div className={`${tab === 'ADDFIELD' && 'bg-body fw-6'} border col-6 text-center p-2 cursor font-14`} onClick={() => setTab('ADDFIELD')}>+Add Fields</div>
              <div className={`${tab === 'FIELDOPTIONS' && 'bg-body fw-6'} border col-6 text-center p-2 cursor font-14`} onClick={() => viewFieldsArry?.length ? setTab('FIELDOPTIONS') : ''}>Field Options</div>
            </div>
            {handleTabs()}
          </div>
        </div>
        <div className=' col-lg-8 col-md-7 col-12' >
          <div className='d-flex flex-column justify-content-between border h-100 p-3' style={{ background: '#F5F5F5' }}>
            <div className='row pb-3'>
              <div className='justify-content-between d-flex border-bottom pb-3 col-12'>
                <div className="col-4  ">
                  <label className='d-block'>Form category</label>
                  <Select
                    value={formCategory}
                    onChange={(e) => setFormCategory(e?.target?.value)}
                    variant="standard"
                    className='w-100'
                    size='small'
                    name='name' >
                    {
                      formCategoryList?.map((cur, index) => {
                        return <MenuItem key={index} value={cur?.id}>{cur?.name}</MenuItem>
                      })
                    }
                  </Select>
                </div>
                <div className="col-4">
                  <label className='d-block text-nowrap'>Form name</label>
                  <TextField id="standard-search"
                    value={templateName}
                    onChange={(e) => setTemplateName(e?.target?.value)}
                    type="search"
                    variant="standard"
                    className='w-100 '
                    name='name' />
                </div>
              </div>
              <div className='row page-content pt-2'>
                {viewFieldsArry?.length > 0 && (
                  viewFieldsArry?.map((result, index) => {
                    if (result?.type === 'Text') {
                      return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                        <div >
                          <div className='d-flex justify-content-between mt-1'>
                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}  </div>
                            <div>
                              <RiFileCopy2Fill className='font-22 me-2 cursor' title='Copy' style={{ color: '#2F90EA' }} onClick={() => copyField(result, index)} />
                              <LiaEditSolid className='font-22 me-2 cursor' title='Edit' style={{ color: '#2F90EA' }} onClick={() => editFieldOption(result, index)} />
                              <IoMdCloseCircle className='font-22 me-2 cursor' title='Remove' style={{ color: '#E74545' }} onClick={() => deleteField(index)} />
                              {/* <Fab aria-label="add" size="small" sx={{ color: pink[500] }} onClick={() => deleteField(index)}>
                                <ClearIcon />
                              </Fab> */}
                              {/* <Fab aria-label="add" className='ms-2' size="small" sx={{ color: blue[700] }} onClick={() => editFieldOption(result, index)} >
                                <EditIcon />
                              </Fab> */}
                            </div>
                          </div>
                        </div>
                        <FormField
                          name={'name'}
                          // value={categoryInputs?.name}
                          // onBlur={(e) => handleBlur(e)}
                          // onChange={(e) => handleInputChange(e)}
                          // error={categoryError?.name}
                          data={{ label: '', placeholder: '', type: 'text' }} />
                      </div>
                    }
                    if (result?.type === 'Date') {
                      return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                        <div >
                          <div className='d-flex justify-content-between mt-1'>
                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}  </div>
                            <div>
                              <RiFileCopy2Fill title='Copy' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => copyField(result, index)} />
                              <LiaEditSolid title='Edit' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => editFieldOption(result, index)} />
                              <IoMdCloseCircle title='Remove' className='font-22 me-2 cursor' style={{ color: '#E74545' }} onClick={() => deleteField(index)} />
                              {/* <RiFileCopy2Fill className='font-22 me-2 cursor' style={{color:'#2F90EA'}} onClick={()=>copyField(result, index)} />
                                <Fab aria-label="add" size="small" sx={{ color: pink[500] }} onClick={() => deleteField(index)}>
                                  <ClearIcon />
                                </Fab>
                                <Fab aria-label="add" className='ms-2' size="small" sx={{ color: blue[700] }} onClick={() => editFieldOption(result, index)} >
                                  <EditIcon />
                                </Fab> */}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 col-12 border p-1 rounded bg-body">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              inputFormat="MM/dd/yyyy"
                              value={null}
                              // onChange={handleDateChange}
                              // onBlur={handleInputChange}
                              className='col-12 border-none'
                              name="dob"
                              renderInput={(params) => <TextField variant="standard" {...params}
                              />}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>

                    }
                    if (result?.type === 'Multiple Choice') {
                      return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                        <div >
                          <div className='d-flex justify-content-between mt-1'>
                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}
                              {
                                <div>
                                  {result?.options?.length > 0 && (
                                    <FormControl>
                                      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                                        {
                                          result?.options?.map((val, index) =>
                                            <FormControlLabel key={index} value={val} control={<Radio />} label={val?.name ? val?.name : val} />
                                          )}
                                      </RadioGroup>
                                    </FormControl>
                                  )}
                                </div>
                              }
                            </div>
                            <div>
                              <RiFileCopy2Fill title='Copy' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => copyField(result, index)} />
                              <LiaEditSolid title='Edit' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => editFieldOption(result, index)} />
                              <IoMdCloseCircle title='Remove' className='font-22 me-2 cursor' style={{ color: '#E74545' }} onClick={() => deleteField(index)} />
                              {/* <RiFileCopy2Fill className='font-22 me-2 cursor' style={{color:'#2F90EA'}} onClick={()=>copyField(result, index)} />
                                <Fab aria-label="add" size="small" sx={{ color: pink[500] }} onClick={() => deleteField(index)}  >
                                  <ClearIcon />
                                </Fab>
                                <Fab aria-label="add" className='ms-2' size="small" sx={{ color: blue[700] }} onClick={() => editFieldOption(result, index)} >
                                  <EditIcon />
                                </Fab> */}
                            </div>
                          </div>
                        </div>
                      </div>

                    }
                    if (result?.type === 'CheckBox') {
                      return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                        <div >
                          <div className='d-flex justify-content-between mt-1'>
                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}
                              <FormGroup row>
                                {
                                  <div>
                                    {result?.options?.length > 0 && (
                                      result?.options?.map((val) =>
                                        <FormControlLabel key={index} value={val} control={<Checkbox />} label={val.name ? val.name : val} className='d-block' />
                                      ))}
                                  </div>
                                }
                              </FormGroup>
                            </div>
                            <div>
                              <RiFileCopy2Fill title='Copy' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => copyField(result, index)} />
                              <LiaEditSolid title='Edit' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => editFieldOption(result, index)} />
                              <IoMdCloseCircle title='Remove' className='font-22 me-2 cursor' style={{ color: '#E74545' }} onClick={() => deleteField(index)} />
                              {/* <RiFileCopy2Fill className='font-22 me-2 cursor' style={{color:'#2F90EA'}} onClick={()=>copyField(result, index)} />
                                <Fab aria-label="add" size="small" sx={{ color: pink[500] }} onClick={() => deleteField(index)} >
                                  <ClearIcon />
                                </Fab>
                                <Fab aria-label="add" className='ms-2' size="small" sx={{ color: blue[700] }} onClick={() => editFieldOption(result, index)} >
                                  <EditIcon />
                                </Fab> */}
                            </div>
                          </div>
                        </div>
                      </div>

                    }
                    if (result?.type === 'Dropdown') {
                      return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                        <div >
                          <div className='d-flex justify-content-between mt-1'>
                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}  </div>
                            <div>
                              <RiFileCopy2Fill title='Copy' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => copyField(result, index)} />
                              <LiaEditSolid title='Edit' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => editFieldOption(result, index)} />
                              <IoMdCloseCircle title='Remove' className='font-22 me-2 cursor' style={{ color: '#E74545' }} onClick={() => deleteField(index)} />
                              {/* <RiFileCopy2Fill className='font-22 me-2 cursor' style={{color:'#2F90EA'}} onClick={()=>copyField(result, index)} />
                                <Fab aria-label="add" size="small" sx={{ color: pink[500] }} onClick={() => deleteField(index)} >
                                  <ClearIcon />
                                </Fab>
                                <Fab aria-label="add" className='ms-2' size="small" sx={{ color: blue[700] }} onClick={() => editFieldOption(result, index)} >
                                  <EditIcon />
                                </Fab> */}
                            </div>
                          </div>
                        </div>
                        <div className=" col-12 mt-3 border rounded bg-body">
                          <Select
                            // value={result.options}
                            onChange={(e) => handleInputChange(e)}
                            variant="outlined"
                            className='w-100'
                            size='small'
                            name='dropdown'>
                            {result?.options?.length > 0 && (
                              result?.options?.map((val, index) => {
                                return <MenuItem key={index} value={val}>{val.name ? val.name : val}</MenuItem>
                              }))
                            }
                          </Select>
                        </div>
                      </div>

                    }
                    if (result?.type === 'Title') {
                      return <div className={`${result?.fieldSize === 'col-4' ? 'mt-3' : ''} ${result?.fieldSize}`} key={index}>
                        <div >
                          <div className='d-flex justify-content-between mt-1'>
                            <div className='Word-Break'>{result?.label} {result?.required && <span className='text-danger'> * </span>}  </div>
                            <div>
                              <RiFileCopy2Fill title='Copy' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => copyField(result, index)} />
                              <LiaEditSolid title='Edit' className='font-22 me-2 cursor' style={{ color: '#2F90EA' }} onClick={() => editFieldOption(result, index)} />
                              <IoMdCloseCircle title='Remove' className='font-22 me-2 cursor' style={{ color: '#E74545' }} onClick={() => deleteField(index)} />
                              {/* <RiFileCopy2Fill className='font-22 me-2 cursor' style={{color:'#2F90EA'}} onClick={()=>copyField(result, index)} />
                              <Fab aria-label="add" size="small" sx={{ color: pink[500] }} onClick={() => deleteField(index)} >
                                <ClearIcon />
                              </Fab>
                              <Fab aria-label="add" className='ms-2' size="small" sx={{ color: blue[700] }} onClick={() => editFieldOption(result, index)} >
                                <EditIcon />
                              </Fab> */}
                            </div>
                          </div>
                        </div>
                        <FormField
                          name={'name'}
                          // value={categoryInputs?.name}
                          // onBlur={(e) => handleBlur(e)}
                          // onChange={(e) => handleInputChange(e)}
                          // error={categoryError?.name}
                          data={{ label: '', placeholder: '', type: 'text' }} />
                      </div>
                    }
                    return null
                  })
                )}
              </div>
            </div>
            {/* <div className="row d-flex justify-content-end mt-4">
          <div className=" d-flex justify-content-start">
            <button type="submit" className="theme-btn bgLightGray  px-5">Discard</button>
            <button type="button" className="theme-btn bgDarkBlue  px-5 ms-2" onClick={() => { handleAddSubmit('SAVED') }}>Save</button>
          </div>
        </div> */}

            <div className="row d-flex justify-content-end mt-4">
              <div className=" d-flex justify-content-start">
                <button className="theme-btn bgLightGray me-2 px-5" onClick={() => { setTemplateModal({ data: null, open: false }) }}>Discard</button>
                {templateModal?.data && filterStatus === 'DRAFT' &&
                  <>
                    <button onClick={() => { handleupdateSubmit('SAVED') }} className=' theme-btn me-3 bgGreen2 px-md-5' >Publish</button>
                    <button onClick={() => { handleupdateSubmit('DRAFT') }} className='theme-btn me-3 bgOrange' >Save as Draft</button>
                  </>
                }
                {templateModal?.data && filterStatus === 'SAVED' &&
                  <>
                    <button onClick={() => { handleupdateSubmit('SAVED') }} className=' theme-btn me-3 bgGreen2 px-md-5' >Update</button>
                    {/* <button onClick={() => { handleAddSubmit('DRAFT') }} className='me-3 bgOrange' >Save as Draft</button>  */}
                  </>
                }
                {!templateModal?.data &&
                  <>
                    <button className="theme-btn bgDarkBlue  px-5 mx-2" onClick={() => { handleAddSubmit('SAVED') }} >Save</button>
                    <button onClick={() => { handleAddSubmit('DRAFT') }} className='theme-btn me-3 bgOrange' >Save as Draft</button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateTemplateLibrary
