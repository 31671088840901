import './form-input.css';
import React from 'react';

function FormField({
  onChange,
  data = {},
  onBlur,
  name,
  value,
  error,
  required = false,
  disabled = false,
  readOnly = false,
  maxLength = 50,
  PointMaxValue,
  className = "box",
  mTop = "mt-2",
  masking = null,
}) {

  // Handling input to allow only numeric input and applying optional masking
  // const handleInput = (e) => {
  //   let inputValue = e.target.value;

  //   // Allow only numbers (digits)

  //   // Apply masking only if `masking` prop is provided and it has a format function
  //   if (masking?.enabled && masking?.formatFunction) {
  //     inputValue = inputValue.replace(/\D/g, ''); // Removes non-numeric characters
  //     inputValue = masking.formatFunction(inputValue);  // Use the dynamic format function
  //   }

  //   // Set max length for non-description fields
  //   if (name !== 'description') {
  //     let maxValue = maxLength;
  //     if (PointMaxValue && inputValue.includes('.')) {
  //       maxValue = PointMaxValue;
  //     }
  //     e.target.maxLength = maxValue;
  //     if (inputValue.length > maxValue) {
  //       inputValue = inputValue.slice(0, maxValue);
  //     }
  //   }

  //   e.target.value = inputValue;
  //   onChange(e); // Call onChange prop to update the parent state
  // };
  const handleInput = (e) => {
    let inputValue = e.target.value;
  
    // Allow only numbers and one decimal point
    
    // Ensure only one decimal point
    if (inputValue.split('.').length > 2) {
      inputValue = inputValue.replace(/[^0-9.]/g, ''); // Removes non-numeric characters except the decimal point
      inputValue = inputValue.replace(/\.+$/, ''); // Remove extra decimal points
    }
  
    // Restrict to one digit after the decimal point (optional)
    // const [integerPart, decimalPart] = inputValue.split('.');
    // if (decimalPart && decimalPart.length > 1) {
    //   inputValue = `${integerPart}.${decimalPart.slice(0, 1)}`;
    // }
  
    // Apply masking if provided
    if (masking?.enabled && masking?.formatFunction) {
      inputValue = masking.formatFunction(inputValue);
    }
  
    // Set max length or value for non-description fields
    if (name !== 'description') {
      let maxValue = maxLength;
      if (PointMaxValue && inputValue.includes('.')) {
        maxValue = PointMaxValue;
      }
      e.target.maxLength = maxValue;
      if (inputValue.length > maxValue) {
        inputValue = inputValue.slice(0, maxValue);
      }
    }
  
    e.target.value = inputValue;
    onChange(e); // Call onChange to update parent state
  };
  
  return (
    <>
      <div className={mTop}>
        {
          data?.label && <label className={'fw-6 font-11   p-0 text-nowrap'}>{data.label} {required && <span className="text-danger">*</span>}</label>
        }
        <div className={`form-group ${className}`}>
          <div className="form-prefix">
            {data.leftIcon && <div className="form-prefix">{data.leftIcon}</div>}
          </div>
          <div className="form-control-wapper">
            <input
              readOnly={readOnly}
              name={name}
              disabled={disabled}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type={data?.type ? data?.type : 'text'} // Use 'text' for better control, but restrict input to digits
              className="custom-form-control font-11"
              placeholder={data.placeholder || ''}
              maxLength={maxLength}
              onInput={handleInput} // Use the custom handleInput function
            />
          </div>
          {data.rightIcon && <div className="form-append">{data.rightIcon}</div>}
        </div>
      </div>
      {
        error && <div className='font-11 mt-1' style={{ color: 'red' }}>{error}</div>
      }
    </>
  );
}

export default FormField;

