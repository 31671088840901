import { createSlice } from "@reduxjs/toolkit";
const teatmentPlan = createSlice({
    name: "teatmentPlan",
    initialState: [],
    reducers: {
        add(state, action) {
            return state = action.payload;
        },
        clearTeatmentPlanData(state) {
            return state = []
        }
    },
});
export const { add,clearTeatmentPlanData} = teatmentPlan.actions;
export default teatmentPlan.reducer;