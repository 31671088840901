import React from "react";
import { Select, MenuItem, InputLabel } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const SelectInput = ({
    readOnly = false,
    ClassName = '',
    Lable = '',
    Error = '',
    isRequired = false,
    SelectValue,
    HandleChange,
    Options = [],
    Name = '',
    keyName,
    onBlur,
    variant = 'standard',
    multiple = false,
    disabled = false,
    message = '',
    extrakey = '',
    selectClass = ''
}) => {

    const renderOptions = () => (
        Options.map((item, index) => (
            <MenuItem
                label={Lable}
                key={`${Name}_${Lable}_${index}`}
                value={item?._id || item?.id || item?.value}
                className="text-capitalize"
            >
                {item[keyName] || item?.name}
                {item[extrakey] && <b> - {message || item[extrakey]}</b>}
            </MenuItem>
        ))
    );
    return (
        <div className={`${ClassName} `}>
            <InputLabel id="demo-simple-select-label" className='font-11 fw-6'>{Lable}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
            <Select
                required={isRequired}
                multiple={multiple}
                variant={variant ? variant : "standard"}
                className={`w-100 ${selectClass} font-11`}
                size="small"
                value={(SelectValue === null || SelectValue === undefined || SelectValue === "") ? multiple ? [] : "" : SelectValue}
                onChange={HandleChange}
                name={Name}
                onBlur={onBlur}
                MenuProps={MenuProps}
                disabled={disabled}
                readOnly={readOnly}
            >
                {Array.isArray(Options) && Options.length > 0 && renderOptions()}
            </Select>
            {Error && <p className="font-11 mt-1 " style={{color:'red'}}>{Error}</p>}
        </div>
    );
};
export default (SelectInput)