/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './auth.css';
import { CLINIC_LIST } from '../../repository/auth';
import { useNavigate } from 'react-router-dom';
import { add } from "../../Slices/AuthSlice";
import useReduxStore from '../../hooks/useReduxStore';
import { Spinner } from '../../Components/common-components/spinner';
import { ToastContainer, toast } from 'react-toastify';

const ClinicLocations = () => {
  const navigate = useNavigate()
  const [active, setActive] = useState(0)
  const [clinicList, setClinicList] = useState([])
  const [selectedClinic, setSelectedClinic] = useState([])
  const { Dispatch,  } = useReduxStore("auth")
  const [spinner, setSpinner] = useState(false);
  const email = localStorage.getItem('email')


  useEffect(() => {
    get_Clinic()
  }, [])

  const get_Clinic = async () => {
    setSpinner(true)
    try {
      let response = await CLINIC_LIST({ email: email })
      setClinicList(response.data)
    }
    catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  const selectClinic = () => {
    // navigate('/appointment_scheduler')
    navigate('/dashboard')
    Dispatch(add(selectedClinic))
    localStorage.removeItem('email')
  }
  return (
    <div className='main-container'>
      {spinner && <Spinner />}
      {/* <ToastContainer /> */}
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="col-10 col-md-6 col-lg-5 col-xl-3 col-xxl-3 d-flex justify-content-center align-items-center" style={{ width: '60%' }}>
          <div className='login-container'>
            <div className="card p-4" >
              <h4 className="mb-2 mt-4 fw-bold text-center">Select Location</h4>
              <div className="p-5 row clinic_Scroll">

                {
                  clinicList?.map((item, index) => {
                    return <div key={index} className='text-center col-4 ms-4 cursor' onClick={() => { setActive(index + 1); setSelectedClinic(item) }} style={{ width: '130px' }}>
                      <div className={`d-flex justify-content-center ${active === index + 1 ? 'clinic_Profile_Active' : 'clinic_Profile'}`}>
                        {
                          active === index + 1 ?
                            <img src={require('../../Assets/images/home2.png')} alt="img" height={50} width={50} />
                            :
                            <img src={require('../../Assets/images/home.png')} alt="img" height={50} width={50} />
                        }
                      </div>
                      <p className={`mt-1 ${active === index + 1 && 'blue1'} fw-bold ms-4`}>{item?.clinicName}</p>
                    </div>
                  }

                  )
                }
                {
                  active > 0 &&
                  <div className='d-flex justify-content-center mt-5'>
                    <button className="cus-btn bgBlue  mt-5 mb-3 text-center" style={{ width: '240px', height: '45px' }} type="submit" onClick={selectClinic}>Next</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClinicLocations
