import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { TextField, InputLabel } from '@mui/material'

export default function index({ SelectedDate, handleDateChange, Label, name, variant, className, size, isRequired, value, Error,disabled }) {
    return (
        <>
            <div className={`${className}`}>
                <InputLabel id="demo-simple-select-label" className='fw-6 font-11 blackLight'>{Label}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker
                        inputFormat="yyyy-MM-ddTHH:mm:ss"
                        value={SelectedDate}
                        onChange={handleDateChange}
                        onBlur={handleDateChange}
                        name={name}
                        size={size}
                        disabled={disabled}
                        renderInput={(params) => <TextField variant={variant ? variant : "outlined"}  {...params}/>}
                    />
                    {Error && <p className='font-13 ' style={{ color: 'red' }}>{Error}</p>}
                </LocalizationProvider>
            </div>
        </>
    )
}