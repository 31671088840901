import { useEffect } from 'react';

const useDisableBrowserBackButton = () => {
    useEffect(() => {
        const disableBackNavigation = (event) => {
            event.preventDefault();
            window.history.forward();
        };
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', disableBackNavigation);
        return () => {
            window.removeEventListener('popstate', disableBackNavigation);
        };
    }, []);
    return {

    }
}

export default useDisableBrowserBackButton