import React from 'react'
import TableHeadings from '../../Components/common-components/table-headings'
import { Radio } from '@mui/material';
import FormField from '../../Components/common-components/form-input';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import Modal from '../../Components/PopUpModal'
import { FaCodeMerge } from "react-icons/fa6";
import { toast } from "react-toastify"
import { handalDOB } from '../../Utils';
const PrimaryAccount = ({ data, methods }) => {
    const { mergeAccountModal, mergeConfirmModal,
        selectedMergeOptions,
        patientMergeList,
        mergeType,
        searchText
    } = data;
    const { setMergeAccountModal,
        setMergeConfirmModal,
        handleMergeRadioChange,
        mergePatinetHandler,
        clearSelectedMerge,
        setSearchText,
        reset,
        applayFilter
    } = methods;

    let primaryPatinet = `${selectedMergeOptions?.primaryData?.firstName ? selectedMergeOptions?.primaryData?.firstName : "-"} ${selectedMergeOptions?.primaryData?.middleName ? selectedMergeOptions?.primaryData?.middleName : ""} ${selectedMergeOptions?.primaryData?.lastName ? selectedMergeOptions?.primaryData?.lastName : "-"}`

    let secondaryPatinet = `${selectedMergeOptions?.secondaryData?.firstName ? selectedMergeOptions?.secondaryData?.firstName : "-"} ${selectedMergeOptions?.secondaryData?.middleName ? selectedMergeOptions?.secondaryData?.middleName : ""} ${selectedMergeOptions?.secondaryData?.lastName ? selectedMergeOptions?.secondaryData?.lastName : "-"}`

    const TableHeadData = [
        { label: '' },
        { label: '#' },
        { label: 'Patient Name' },
        { label: 'Gender' },
        { label: 'Age' },
    ];

    return (
        <div className='pb-3' >
            <div >
                <div className="d-lg-flex align-items-center px-3 pb-3">
                    <div className='mx-2 mx-sm-0'>
                        <FormField
                            value={searchText}
                            name={"searchText"}
                            onChange={(e) => setSearchText(e.target.value)}
                            data={{ placeholder: 'Search', rightIcon: '', type: 'text' }} />
                    </div>
                    <div className="d-flex mt-3">
                        <button className="theme-btn bgDarkBlue ms-2 ms-sm-0 ms-lg-3" onClick={() => applayFilter("Merge")}>
                            <SearchIcon style={{ height: "20px" }} /> Search
                        </button>
                        <button className="theme-btn bgDarkBlue mx-3" onClick={() => reset("Merge")}>
                            <RestartAltIcon style={{ height: "20px" }} /> Reset
                        </button>
                    </div>
                </div>
                <div style={{ height: "70vh", overflowY: "auto" }}>
                    <table className="customTable">
                        <TableHeadings className={'p-2 text-center bgGrey2'} TableHeadData={TableHeadData} />
                        <tbody>
                            {patientMergeList?.map((res, index) => (
                                <tr key={index} >
                                    <td width={'20%'}>
                                        <Radio
                                            checked={selectedMergeOptions.primary === res?.id || selectedMergeOptions.secondary === res?.id}
                                            onChange={() => handleMergeRadioChange(res)}
                                        />
                                    </td>
                                    <td className='text-center' width={'10%'}>{index + 1}</td>
                                    <td className='text-center blue1' width={'30%'} >{`${res?.firstName ? res?.firstName : "-"} ${res?.middleName ? res?.middleName : ""} ${res?.lastName ? res?.lastName : "-"}`}</td>
                                    <td className='text-center' width={'25%'}>{res?.gender || "--"}</td>
                                    <td className='text-center' width={'15%'}>{res?.dob ? handalDOB(res?.dob) : "--"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {!patientMergeList?.length && <div className="text-center mt-2">No record found</div>}
                </div>
            </div>

            <div className='d-flex mt-4 justify-content-center p-3 py-4'>
                <div>
                    <button className='theme-btn bgLightGray px-30' onClick={() => { clearSelectedMerge(); setMergeAccountModal({ open: false }) }}>
                        Cancel
                    </button>
                </div>
                <div className='ms-3'>
                    <button className='theme-btn bgLightGreen2 px-5' onClick={() => { !selectedMergeOptions.secondary ? toast.warn("Please select secondary patient") : setMergeConfirmModal((prev) => ({ ...prev, open: true })) }}>
                        {mergeAccountModal?.type === mergeType?.duplicate ? 'Next' : 'Merged'}
                    </button>
                </div>
            </div>

            {/* merge account Modal */}
            <Modal
                size="md"
                show={mergeConfirmModal?.open}
                onHide={() => {
                    setMergeConfirmModal({ data: {}, open: false })
                    clearSelectedMerge()
                }}
                bodyContent={
                    <div>
                        <div className="text-center col-12">
                            <div className='mt-3 mb-4'>
                                <FaCodeMerge className='cursor blue1' style={{ fontSize: '50px' }} />
                            </div>
                            <div className="fw-7 font-20 black1">Accounts Merged as{mergeAccountModal?.type === mergeType?.duplicate ? " duplicate" : "Family"} </div>
                            <div className="grey2 font-14 my-2">
                                <span className='font-16 blue1 fw-6'>
                                    {primaryPatinet}</span> and <span className='font-16 blue1 fw-6'>
                                    {secondaryPatinet}</span> are merged into one account successfully
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3 mb-4">
                            <button type="button" className="theme-btn bgDarkBlue2 col-5" onClick={mergePatinetHandler}>Ok</button>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default PrimaryAccount;
