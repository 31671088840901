import { toast } from "react-toastify";
import { fileTypes } from "../constant/index";
import { UPLOAD_FILE } from "../repository/common";
const useUploadImage = () => {
    const UploadImage = async (event, FileRef, type, setLoader, setImgUrl) => {
        const fileData = event.target.files[0];
        const fd = new FormData()
        if (fileData?.size / 1024 / 1024 > 10) return toast.warn("Please upload image less than 10 MB");
        if (!fileTypes.Img_Type.includes(fileData?.type)) return toast.warn("Only image format allowed");
        if (fileData?.size / 1024 / 1024 < 10 && fileTypes.Img_Type.includes(fileData?.type)) {
            fd.append('uploadType', type)
            fd.append('files', fileData)
            setLoader(true)
            try {
                let response = await UPLOAD_FILE(fd)
                setImgUrl(response?.data?.[0]?.fileName)
                FileRef.current.value = null;
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setImgUrl(null)
                setLoader(false)
            }
        }
    };

    const UploadFile = async (event, FileRef, type, setLoader, setFileUrl) => {
        const fileData = event.target.files[0];
        const fd = new FormData();
        // Check if the file exceeds 10MB
        if (fileData?.size / 1024 / 1024 > 10) {
            return toast.warn("Please upload a file less than 10 MB");
        }
        // Check if the file type is allowed (image or PDF)
        const allowedTypes = [...fileTypes.Img_Type, ...fileTypes.pdf_File_Type];
        if (!allowedTypes.includes(fileData?.type)) {
            return toast.warn("Only image or PDF formats are allowed");
        }
        if (fileData?.size / 1024 / 1024 <= 10 && allowedTypes.includes(fileData?.type)) {
            fd.append('uploadType', type);
            fd.append('files', fileData);
            setLoader(true);
            try {
                const response = await UPLOAD_FILE(fd);
                setFileUrl(response?.data?.[0]?.fileName);
                FileRef.current.value = null;
                setLoader(false);
            } catch (error) {
                toast.error(error.message);
                setFileUrl(null);
                setLoader(false);
            }
        }
    };
    return {
        UploadImage,
        UploadFile
    }
}
export default useUploadImage