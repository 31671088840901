import { createSlice } from "@reduxjs/toolkit";
const towFactorSlice = createSlice({
    name: "TowFactow",
    initialState: {},
    reducers: {
        addTowFactor(state, action) {
            return state = action.payload;
        },
    },
});
export const { addTowFactor } = towFactorSlice.actions;
export default towFactorSlice.reducer;