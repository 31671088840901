import React from 'react'
import './header.css';
import { NavLink } from 'react-router-dom';
import AddIcon from "../../Assets/Clinic_head_icons/addIcon.png"
import Modal from "../../Components/PopUpModal";
import AddEditAppointment from '../../Pages/Appointment Scheduler/AddEditAppointment';
import useHeader from './Hooks/useHeader';
// import { IoEye } from "react-icons/io5";
// import { ImFileText } from "react-icons/im";
// import { IoMdEyeOff } from "react-icons/io";

const ChartingHeader = () => {
    const { methods, data } = useHeader()
    const { appointmentModal,
        // hippModeOnOff
    } = data
    const {
        setAppointmentModal,
        // setHippModeOnOff

    } = methods
    const appointmentType = {
        add: 'ADD'
    }

    return (
        <>
            <header id="header" className={`nestedHeader  d-flex align-items-center  `} style={{ background: "#0F6CBD" }}>
                <nav>
                    <ul className="nav-links">
                        {/* <li onClick={() => setAppointmentModal({ data: '', type: appointmentType?.add, open: true })}><img src={AddIcon} />Create Appointment</li> */}
                        {/* <li className='d-flex align-items-center ' onClick={() => (setHippModeOnOff(!hippModeOnOff))}> {hippModeOnOff ? <IoMdEyeOff className='font-18 me-1' /> : <IoEye className='font-18 me-1' />}  Privacy Mode</li> */}
                        <NavLink to={"/perio-chart"}><li><img src={AddIcon} alt='' /> Perio Chart</li></NavLink>
                        {/* <li><NavLink to={"/perio-graph"}><img src={AddIcon} alt='' /> Perio Graph</NavLink></li> */}
                        <NavLink to={"/e-prescription"}><li><img src={AddIcon} alt='' /> E-Prescription </li></NavLink>
                        <NavLink to={"/patient-notes"}><li><img src={AddIcon} alt='' /> Notes </li></NavLink>
                        {/* <li className='d-flex align-items-center '><NavLink ><ImFileText className='font-18 me-1' />Appointment Requests</NavLink></li> */}
                    </ul>
                </nav>
            </header>


            {/* edit appointment Modal */}
            <Modal className="p-0"
                size="xl" show={appointmentModal?.open} onHide={() => {
                    setAppointmentModal({ data: null, open: false })
                    // clearForm()
                    // setStateError({});
                }}
                headerContent={<h5 className="py-2 mb-0 fw-6 font-16">Create Appointment</h5>}

                bodyContent={
                    <AddEditAppointment data={data} type={appointmentType} methods={methods} />
                }
            />

        </>
    )
}

export default ChartingHeader