import { axiosInstance, baseUrl, handleErrors } from "./auth";



export const NOTIFICATION_LIST = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/notification/list`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}

export const READ_NOTIFICATION = async (params) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/notification/changestatus`, params);
        return response.data;
    } catch (error) {
        handleErrors(error)
    }
}