export const chartsText = ['Bleeding On Probing', 'Gingival Margin', 'Probing Depth', 'Vertical Defect', 'Mobility', 'Furcation', 'Suppuration', 'Cal']
export const chartNotAssigned = ['Radiographic Bone Loss', 'Calculus', 'Plaque', 'Horizontal Probing']
export const chart_Names = ['radiographic_bone_loss', 'bleeding_on_probing', 'horizontal_probing', 'gingival_margin', 'calculus', 'probing_depth', 'vertical_defect', 'plaque', 'mobility', 'furcation', 'suppuration', 'cal']
export const Assigned_Chart_Names = ['bleeding_on_probing', 'gingival_margin', 'probing_depth', 'vertical_defect', 'mobility', 'furcation', 'suppuration', 'cal']


export const teeths = ["1", "2", "3", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "14", "15", "16"]
export const teethsReverse = ["32", "31", "30", "T", "S", "R", "Q", "P", "O", "N", "M", "L", "K", "19", "18", "17"]

export const teethForValue5 = ["1", "2", "3", "A", "B", "C", "7", "8", "9", "10", "H", "I", "J", "14", "15", "16"]
export const teethReverseForValue5 = ["32", "31", "30", "T", "S", "27", "26", "25", "24", "23", "22", "L", "K", "19", "18", "17"]


export const teethToHideForValue2 = ["D", "E", "F", "G", "Q", "P", "O", "N"];
export const teethToHideForValue3 = ["B", "C", "D", "E", "F", "G", "H", "I", "S", "R", "Q", "P", "O", "N", "M", "L"];
export const teethToHideForValue4 = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "T", "S", "R", "Q", "P", "O", "N", "M", "L", "K"];
export const teethToHideForValue5 = ["3", "A", "B", "C", "7", "8", "9", "10", "H", "I", "J", "14", "30", "T", "S", "27", "26", "25", "24", "23", "22", "L", "K", "19"];
